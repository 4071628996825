import axios from 'axios';
import * as apis from '../configs/apis';

export const getMessage = async (token: string, user1: string, user2: string) => {
  try {
    const getMessageResponse: any = await axios.get(apis.MESSAGE_API + '/message/' + user1 + '/' + user2, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getMessageResponse.data;
  } catch (err) {
    console.log(`Error returned from GET message API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getUnreadCount = async (token: string) => {
  try {
    const getCountResponse: any = await axios.get(apis.MESSAGE_API + '/unreadCount', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCountResponse.data;
  } catch (err) {
    console.log(`Error returned from GET unread count API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const resetUnread = async (token: string, messageId: string) => {
  try {
    await axios.put(apis.MESSAGE_API + '/message/' + messageId + '/unread/reset', {}, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from PUT reset unread API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postMessage = async (token: string, messageDetails) => {
  try {
    await axios.post(apis.MESSAGE_API + '/message', messageDetails, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST message API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getMessageByUser = async (token: string, userId: string) => {
  try {
    const getMessageResponse: any = await axios.get(apis.MESSAGE_API + '/message/' + userId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getMessageResponse.data;
  } catch (err) {
    console.log(`Error returned from GET message by user API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}