import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { Notifications } from 'expo';
import styles from './homeScreen.styles';
import Header from '../components/header';
import Icons from 'react-native-vector-icons/AntDesign';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class HomeScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
  }
  
  componentDidMount() { 
    Notifications.addListener(this.handleNotificationResponse); 
  }
  
  handleNotificationResponse(notification: any) { 
    if (notification.origin === 'selected') {
      this.props.navigation.navigate('Notification');
    }
  }

  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }

  render() {
    return (
      <View style={styles.homeContainer}>
        <Header headerText='Home' onLogout={this.props.screenProps.onLogout} />
        <View style={styles.brandContainer}><Text style={styles.brandText}>Euro Potential Sdn Bhd</Text></View>
        {/* TODO: Temporary use vector icon. To use back custom icon component in future */}
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('MeScreen')}>
          <Text style={styles.itemText}><Icons name={'user'} color={'gray'} size={25} />     About Me</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Material')}>
          <Text style={styles.itemText}><Icons name={'database'} color={'gray'} size={25} />     Material</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Material', { mode: 'brand' })}>
          <Text style={styles.itemText}><Icons name={'skin'} color={'gray'} size={25} />     Brand</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Enquiry')}>
          <Text style={styles.itemText}><Icons name={'shoppingcart'} color={'gray'} size={25} />     Enquiry</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('MouldingGuideScreen')}>
          <Text style={styles.itemText}><Icons name={'book'} color={'gray'} size={25} />     Moulding Guide</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => alert('Coming Soon!')}>
          <Text style={styles.itemText}><Icons name={'team'} color={'gray'} size={25} />     Service/R&D</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('Message')}>
          <Text style={styles.itemText}><Icons name={'message1'} color={'gray'} size={25} />     Contact Us</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default HomeScreen;