import React, { Component } from 'react';
import { View } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { Table, Row } from 'react-native-table-component';
import styles from './salesAboutMeScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import { getUserById } from '../services/userService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  department: string;
  salesMonthly: string;
  salesYearly: string;
}

class SalesAboutMeScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      department: '',
      salesMonthly: '',
      salesYearly: ''
    }
  }

  async componentDidMount() {
    let getSalesResponse: any = await getUserById(this.props.screenProps.token, this.props.screenProps.userId);

    if (getSalesResponse) {
      this.setState({
        department: getSalesResponse.department,
        salesMonthly: getSalesResponse.salesMonthly,
        salesYearly: getSalesResponse.salesYearly,
      });
    }
  }

  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('SalesScreen')} />
        <Table>
          <Row data={['Department', this.state.department]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
          <Row data={['Sales Monthly', this.state.salesMonthly]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
          <Row data={['Sales Yearly', this.state.salesYearly]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
        </Table>
      </View>
    );
  }
}

export default SalesAboutMeScreen;
