import axios from 'axios';
import * as apis from '../configs/apis';

export const getAllFeed = async (token: string) => {
  try {
    const getFeedResponse: any = await axios.get(apis.FEED_API + '/feed/all', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getFeedResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET feed/all API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postFeed = async (token: string, feedDetails: any) => {
  try {
    let request: any = new XMLHttpRequest();
    request.open('POST', apis.FEED_API + '/feed'); 
    request.send(feedDetails);
    return;
  } catch (err) {
    console.log(`Error returned from POST feed API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const deleteFeedAutoByDate = async (token: string) => {
  try {
    await axios.delete(apis.FEED_API + '/feed', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from DELETE date by feed API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const deleteFeed = async (token: string, feedId: string) => {
  try {
    await axios.delete(apis.FEED_API + '/feed/feedId/' + feedId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from DELETE feed API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
