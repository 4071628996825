import React, { Component } from 'react';
import { Text, View, Button } from 'react-native';
import styles from './header.styles';

interface CustomInputProps {
  headerText: string;
  onLogout: Function;
}

class Header extends Component<CustomInputProps> {
  onLogout() {
    this.props.onLogout();
  }
  
  render() {
    return (
      <View style={styles.header}>
        <View style={styles.headerTextContainer}><Text style={styles.headerText}>{this.props.headerText}</Text></View>
        <View style={styles.headerLogoutContainer}><Button title='Logout' onPress={this.onLogout.bind(this)} /></View>
      </View>
    );
  }
}

export default Header;