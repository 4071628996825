import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from 'moment';
import styles from './quoteListing.styles';
import Header from '../components/header';
import RowList from '../components/rowList';
import Icon from '../components/icon';
import Loader from '../components/loader';
import BackBar from '../components/backBar';
import * as SupplierService from '../services/supplierService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  quoteList: Array<any>;
  isLoading: boolean;
}

class QuoteListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      quoteList: [],
      isLoading: true
    }
    
    this.onClickQuoting = this.onClickQuoting.bind(this);
  }

  async componentDidMount() {
    let quoteListingResponse: any = {};
  
    this.setState({isLoading:true})
    if (this.props.screenProps.role === 'management') {
      quoteListingResponse = await SupplierService.getQuotingByStatus(this.props.screenProps.token, 'approved');
    } else {
      quoteListingResponse = await SupplierService.getQuotingBySupplier(this.props.screenProps.token, this.props.screenProps.companyId);
    }
    
    if (quoteListingResponse) {
      quoteListingResponse.sort(function (a: any, b: any) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });

      let quoteList: Array<any> = [];
      let getCompanyResponse: any;
      for (const item of quoteListingResponse) {
        getCompanyResponse = await CompanyService.getCompany(this.props.screenProps.token, item.supplier);        
        
        quoteList.push({
          "title": item.brand + " " + item.product + "\r\nSupplier: " + getCompanyResponse[0].name,
          "content": "Price: " + item.price + "\r\nStatus: " + item.status,
          "date": moment(new Date(item.date)).format('MMMM Do YYYY, h:mm:ss a'),
          "unread": false,
          "id": item._id,
          "onPress": () => this.onClickQuoting(item._id)
        });
      }

      this.setState({ quoteList: quoteList, isLoading: false });
    }
  }
  
  onClickQuoting(quotingId: string) {
    if (this.props.screenProps.role === 'management') {
      this.props.navigation.navigate('QuotingScreen', { quotingId: quotingId })
    } else {
      this.props.navigation.navigate('Quoting', { quotingId: quotingId })
    }
  }

  render() {
    return (
      <View style={styles.quoteContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Quoting List' onLogout={this.props.screenProps.onLogout} />
        {this.props.screenProps.role === 'supplier' && <BackBar onPress={() => this.props.navigation.navigate('MeScreen')} />}
        {this.state.quoteList.length === 0 && <Text style={styles.emptyMessage}>You have no quoting!</Text>}
        {this.state.quoteList.length > 0 && <RowList dataList={this.state.quoteList} />}
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default QuoteListing;
