import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  messageContainer: {
    flex: 1
  },
  chatContainer: {
    flex: 1
  },
  customInputContainer: {
    borderTopWidth: 0.5,
    borderTopColor: 'lightgray',
    flexDirection: 'row'
  },
  customInputText: {
    flex: 3
  },
  customInputTextContainer: {
    borderBottomWidth: 0
  },
  customInputSend: {
    flex: 1
  },
  customInputSendButton: {
    backgroundColor: 'transparent'
  },
  customInputSendButtonText: {
    color: 'blue'
  }
});

export default styles;