import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './managementFeedScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import * as feedService from '../services/feedService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class ManagementFeedScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(destination: string, params: any) {
    this.props.navigation.navigate(destination, params);
  }

  async componentDidMount() {
    await feedService.deleteFeedAutoByDate(this.props.screenProps.token);
  }

  render() {
    return (
      <View style={styles.managementFeedContainer}>
        <Header headerText='Feed' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('ManagementAdminScreen')} />
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('UploadFeedScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Upload New Wall Feed</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('FeedScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     View Wall Feed</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default ManagementFeedScreen;
