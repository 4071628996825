import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './aboutMeScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class AboutMe extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(destination: string, params: any) {
    this.props.navigation.navigate(destination, params);
  }
  
  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.onNavigate('HomeScreen')} />
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CompanyDetailScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Company Detail</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('OrderListing', { 'showCompletedOnly': true })}>
          <Text style={styles.itemText}>{'\u2022'}     Purchase History</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('OrderListing', { 'showIncompleteOnly': true })}>
          <Text style={styles.itemText}>{'\u2022'}     Purchase & Goods Delivery</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CreditLimitScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Credit Limit/Debtor Ageing</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => alert('Coming Soon!')}>
          <Text style={styles.itemText}>{'\u2022'}     Documents</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default AboutMe;