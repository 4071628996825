import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { Table, TableWrapper, Row } from 'react-native-table-component';
import styles from './allCustomerListing.styles';
import Header from '../components/header';
import * as userService from '../services/userService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class AllCustomerListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }
  }

  async componentDidMount() {
    let customerListingResponse: any = await userService.getCustomer(this.props.screenProps.token);
    let salesListingResponse: any = await userService.getSales(this.props.screenProps.token);

    if (customerListingResponse) {
      let customerListing: Array<any> = [];
      let tempArray: Array<string>;
      let customerSales: any;
      
      customerListingResponse.map(function(data) {
        customerSales = salesListingResponse.filter(function(salesData) {
          return salesData._id === data.salesId;
        });
      
        tempArray = [];
        tempArray.push(data.email);
        
        if(customerSales[0]) {
          tempArray.push(customerSales[0].email);
        }
        
        customerListing.push(tempArray);
      });
      
      this.setState({ customerListing: customerListing });
    }
  }

  render() {
    return (
      <View style={styles.customerContainer}>
        <Header headerText='Customer Listing' onLogout={this.props.screenProps.onLogout} />
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>No Customer!</Text>}
        {this.state.customerListing.length > 0 && 
          <View style={styles.customerContainer}>
            <Table>
              <Row data={['Customer', 'Sales']} flexArr={[1, 1]} style={styles.tableHeader} textStyle={styles.headerText}/>
            </Table>
            <ScrollView style={styles.dataWrapper}>
              <Table>
                {
                  this.state.customerListing.map((rowData, index) => (
                    <Row
                      key={index}
                      data={rowData}
                      flexArr={[1,1]}
                      style={[styles.tableRow, index%2 && {backgroundColor: '#F2F2F2'}]}
                      textStyle={styles.tableText}
                    />
                  ))
                }
              </Table>
            </ScrollView>
          </View>
        }
      </View>
    );
  }
}

export default AllCustomerListing;
