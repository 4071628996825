import React, { Component } from 'react';
import { View } from 'react-native';
import styles from './app.styles';
import LoginScreen from './views/loginScreen';
import ResetPasswordScreen from './views/resetPasswordScreen';
import CustomerHome from './views/customerHome';
import CustomerManagementHome from './views/customerManagementHome';
import SalesHome from './views/salesHome';
import ManagementHome from './views/managementHome';
import SupplierHome from './views/supplierHome';
import * as userService from './services/userService';
import * as appConfig from './configs/apis';

interface CustomInputProps {
}

interface CustomInputStates {
  isLogin: boolean;
  token: string;
  role: string;
  userId: string;
  companyId: string;
  tier: string;
  department: string;
  requireUpdate: boolean;
  unreadCount: number;
  resetPasswordMode: boolean;
}

class App extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      isLogin: false,
      token: '',
      role: '',
      userId: '',
      companyId: '',
      tier: '',
      department: '',
      requireUpdate: false,
      unreadCount: 0,
      resetPasswordMode: false
    }

    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
    this.switchResetPassword = this.switchResetPassword.bind(this);
  }

  async componentDidMount() {
    let version: any = await userService.getVersion();

    if (version) {
      if (version.lastSupportedVersion > appConfig.CURRENT_VERSION) {
        this.setState({ requireUpdate: true });
      } else if (version.latest > appConfig.CURRENT_VERSION) {
        alert('New update is available. Please update it from Play Store/App Store.');
      }
    }
  }

  onLogin(token: string, role: string, userId: string, companyId: string, tier: string, unreadCount: number, department: string) {    
    this.setState({ isLogin: true, token: token, role: role, userId: userId, companyId: companyId, tier: tier, unreadCount: unreadCount, department: department });
  }
  
  onLogout() {
    this.setState({ isLogin: false });
  }
  
  backToLogin() {
    this.setState({ resetPasswordMode: false });
  }
  
  switchResetPassword() {
    this.setState({ resetPasswordMode: true });
  }

  render() {
    return (
      <View style={styles.container}>
        {this.state.requireUpdate && alert('Your APP is outdated. Please update it from Play Store/App Store to continue using this app.')}
        {!this.state.requireUpdate && !this.state.isLogin && !this.state.resetPasswordMode && <LoginScreen onLogin={this.onLogin} onLogout={this.onLogout} switchResetPassword={this.switchResetPassword} />}
        {this.state.resetPasswordMode && <ResetPasswordScreen backToLogin={this.backToLogin} />}
        {this.state.isLogin && this.state.role === 'customer' && this.state.tier === 'staff' && <CustomerHome screenProps={{ token: this.state.token, role: this.state.role, userId: this.state.userId, onLogout: this.onLogout, companyId: this.state.companyId, tier: this.state.tier, unreadCount: this.state.unreadCount }} />}
        {this.state.isLogin && this.state.role === 'customer' && this.state.tier !== 'staff' && <CustomerManagementHome screenProps={{ token: this.state.token, role: this.state.role, userId: this.state.userId, onLogout: this.onLogout, companyId: this.state.companyId, tier: this.state.tier, unreadCount: this.state.unreadCount }} />}
        {this.state.isLogin && this.state.role === 'sales' && <SalesHome screenProps={{ token: this.state.token, role: this.state.role, userId: this.state.userId, onLogout: this.onLogout, unreadCount: this.state.unreadCount }} />}
        {this.state.isLogin && this.state.role === 'management' && <ManagementHome screenProps={{ token: this.state.token, role: this.state.role, userId: this.state.userId, onLogout: this.onLogout, unreadCount: this.state.unreadCount, department: this.state.department }} />}
        {this.state.isLogin && this.state.role === 'supplier' && <SupplierHome screenProps={{ token: this.state.token, role: this.state.role, userId: this.state.userId, onLogout: this.onLogout, companyId: this.state.companyId, tier: this.state.tier, unreadCount: this.state.unreadCount }} />}
      </View>
    );
  }
}

export default App;