import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    width: 150,
    height: 200,
    flexGrow: 1,
    borderWidth: 1,
    borderColor: '#EBE9E9'
  },
  cardTitle: {
    paddingTop: 20,
    paddingBottom: 15,
    height: 100
  },
  cardTitleText: {
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardContent: {
    paddingLeft: 10
  },
  cardContentText: {
    fontSize: 14,
    textAlign: 'center'
  },
  cardAction: {
    width: 90,
    paddingTop: 35,
    alignSelf: 'center'
  }
});

export default styles;