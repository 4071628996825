import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  resetContainer: {
    flex: 1
  },
  formContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 50
  }
});

export default styles;
