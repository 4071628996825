import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import AnimatedLoader from 'react-native-animated-loader';
import styles from './loader.styles';

interface CustomInputProps {
  isLoading: boolean;
}

class Loader extends Component<CustomInputProps> {
  render() {
    return (
      <View>
        { Platform.OS !== 'web' && <AnimatedLoader
          visible={this.props.isLoading}
          overlayColor='rgba(255,255,255,0.75)'
          animationStyle={styles.loader}
          speed={1}
        /> }
      </View>
    );
  }
}

export default Loader;