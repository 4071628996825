import React from 'react';
import { createAppContainer, StackActions, NavigationActions } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/icon';
import SupplierHomeScreen from './supplierHomeScreen';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import SupplierMeScreen from './supplierAboutMeScreen';
import NotificationScreen from './notificationScreen';
import CompanyDetailScreen from './companyDetailScreenNonEditable';
import QuotingScreen from './supplierQuotingScreen';
import QuoteListing from './quoteListing';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import ConsumptionListing from './consumptionListing';
import MessageListing from './messageListing';

const MeStack = createStackNavigator({
  HomeScreen: { screen: SupplierHomeScreen },
  MeScreen: { screen: SupplierMeScreen },
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  QuotingListing: { screen: QuoteListing },
  ConsumptionListing: { screen: ConsumptionListing }
}, {
  headerMode: 'none',
  initialRouteName: 'HomeScreen'
});

const MessageStack = createStackNavigator({
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const ProjectDevelopmentStack = createStackNavigator({
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'ProjectDevelopmentListing'
});


const TabNavigator = createBottomTabNavigator({
  Home: MeStack,
  Quoting: QuotingScreen,
  Message: MessageStack,
  'Project Development': ProjectDevelopmentStack,
  Notification: NotificationScreen
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';

      if (routeName === 'Home') {
        iconName = 'home';
      } else if (routeName === 'Quoting') {
        iconName = 'bank';
      } else if (routeName === 'Message') {
        iconName = 'message1';
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Project Development') {
        iconName = 'team';
      } else if (routeName === 'Notification') {
        iconName = 'bells';
      }

      return <Icon name={iconName} color={color} badgeCount={badgeCount} />;
    }
  }),
  tabBarOptions: {
    activeTintColor: 'tomato',
    inactiveTintColor: 'gray',
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);