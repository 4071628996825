import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  quotingContainer: {
    flex: 1
  },
  formContainer: {
    flex: 2
  },
  scrollViewContainer: { 
    flexGrow: 1, 
    justifyContent: "center" 
  },
  form: {
    width: 250,
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  inputContainer: {
    paddingBottom: 20
  },
  button: {
    width: 200,
    paddingTop: 10,
    alignSelf: 'center'
  },
  errorMessage: {
    color: 'red',
    alignSelf: 'center'
  }
});

export default styles;