import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './managementAdminScreen.styles';
import Header from '../components/header';
import Icons from 'react-native-vector-icons/AntDesign';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class ManagementAdminScreen extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }
  
  onNavigate(destination: string) {
    this.props.navigation.navigate(destination);
  }

  render() {
    return (
      <View style={styles.homeContainer}>
        <Header headerText='Admin' onLogout={this.props.screenProps.onLogout} />
        {this.props.screenProps.department === 'super' && <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ManagementUserScreen')}>
          <Text style={styles.itemText}><Icons name={'user'} color={'gray'} size={25} />     View/Add User</Text>
        </TouchableOpacity>}
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ManagementFeedScreen')}>
          <Text style={styles.itemText}><Icons name={'switcher'} color={'gray'} size={25} />     View/Create Wall Feed</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('ConsumptionListing')}>
          <Text style={styles.itemText}><Icons name={'solution1'} color={'gray'} size={25} />     Consumption List</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default ManagementAdminScreen;