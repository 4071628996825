import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  aboutMeContainer: {
    backgroundColor: '#F2F1F1',
    flex: 1
  },
  item: {
    maxHeight: 100,
    flexGrow: 1,
    borderWidth: 0.5,
    borderColor: 'gray',
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingLeft: 10
  },
  itemText: { 
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 20
  }
});

export default styles;