import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    color:"green"
  },
  formContainer: {
    flex: 1,
    alignItems: 'center',
    paddingBottom: 50
  },
  resetContainer: {
    alignItems: 'center',
    paddingBottom: 50
  },
  resetText: {
    textDecorationLine: 'underline',
    color: 'blue'
  },
  logo: {
    width: 150,
    height: 150
  }
});

export default styles;
