import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { Notifications } from 'expo';
import styles from './managementUserScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class ManagementUser extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
    this.handleNotificationResponse = this.handleNotificationResponse.bind(this);
  }
  
  componentDidMount() { 
    Notifications.addListener(this.handleNotificationResponse); 
  }
  
  handleNotificationResponse(notification: any) { 
    if (notification.origin === 'selected') {
      this.props.navigation.navigate('Notification');
    }
  }

  onNavigate(destination: string, params: any) {
    this.props.navigation.navigate(destination, params);
  }

  render() {
    return (
      <View style={styles.managementUserContainer}>
        <Header headerText='User' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('ManagementAdminScreen')} />
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('AllCustomerListing')}>
          <Text style={styles.itemText}>{'\u2022'}     List All Customer</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default ManagementUser;