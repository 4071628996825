import React, { Component } from 'react';
import { View } from 'react-native';
import styles from './resetPasswordScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import BackBar from '../components/backBar';
import * as userService from '../services/userService';

interface CustomInputProps {
  backToLogin: Function;
}

interface CustomInputStates {
  formInput: Array<any>;
  username: string;
  password: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
}

class ResetPasswordScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      username: '',
      password: '',
      haveError: false,
      errorMessage: '',
      isLoading: false
    }

    this.generateForm = this.generateForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  componentDidMount() {
    this.generateForm();
  }

  generateForm() {
    let formInput: Array<any> = [
      { placeholder: 'Username', onChangeText: (value) => this.onChange('username', value), value: this.state.username },
      { placeholder: 'New Password', onChangeText: (value) => this.onChange('password', value), secureTextEntry: true, value: this.state.password }
    ];

    this.setState({ formInput: formInput });
  }

  onChange(name: string, input: string) {
    this.setState({ [name]: input }, () => this.generateForm());
  }
  
  backToLogin() {
    this.props.backToLogin();
  }

  async onResetPassword() {
    this.setState({ isLoading: true });
    const resetResponse: any = await userService.resetPassword(this.state.username, this.state.password);

    if (resetResponse.success) {
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'You will received an email shortly to verify the reset password request.' });
    } else {
      this.setState({ haveError: true });
      this.setState({ errorMessage: 'Username does not exists.' });
    }
    
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <View style={styles.resetContainer}>
        <Header headerText='Reset Password' onLogout={() => {}} />
        <BackBar onPress={this.backToLogin} />
        <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Reset Password'} buttonOnPress={this.onResetPassword} />
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default ResetPasswordScreen;