import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './supplierAboutMeScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

class SupplierAboutMe extends Component<CustomInputProps> {
  constructor(props: CustomInputProps) {
    super(props);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onNavigate(destination: string, params: any) {
    this.props.navigation.navigate(destination, params);
  }
  
  render() {
    return (
      <View style={styles.aboutMeContainer}>
        <Header headerText='About Me' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.onNavigate('HomeScreen')} />
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('CompanyDetailScreen')}>
          <Text style={styles.itemText}>{'\u2022'}     Company Detail</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.item} onPress={() => this.onNavigate('QuotingListing')}>
          <Text style={styles.itemText}>{'\u2022'}     Quoting History</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default SupplierAboutMe;