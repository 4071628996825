import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  row: {
    backgroundColor: 'white',
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#EBE9E9'
  },
  container: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 10,
    flex: 1,
    flexDirection: 'row'
  },
  rowIcon: {
    width: 130,
    flex: 1,
    flexDirection: 'row'
  },
  rowTextContainer: {
    flex: 5
  },
  rowTitleText: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  rowTitleIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  rowContentText: {
    fontSize: 12
  },
  rowDate: {
    paddingTop: 15,
    flexDirection: 'row'
  },
  rowDateText: {
    fontSize: 12,
    color: 'gray',
    paddingTop: 5
  }
});

export default styles;