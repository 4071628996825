import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import styles from './row.styles';
import Icon from './icon';

interface CustomInputProps {
  title: string;
  content: string;
  date: string;
  unread: number;
  id: string;
  onPress: Function;
}

class Row extends Component<CustomInputProps> {
  onPress() {
    this.props.onPress();
  }

  render() {
    return (
      <TouchableOpacity style={styles.row} onPress={this.onPress.bind(this)}>
        <View style={styles.container}>
          <View style={styles.rowIcon}><Icon name={'infocirlceo'} color={'gray'} badgeCount={0} /></View>
          <View style={styles.rowTextContainer}>
            <Text style={styles.rowTitleText}>{this.props.title}</Text>
            <Text numberOfLines={5} ellipsizeMode='tail' style={styles.rowContentText}>{this.props.content}</Text>
            {this.props.date !== '' && <View style={styles.rowDate}>
              <Icon name={'clockcircleo'} size={15} color={'lightgray'} badgeCount={0} />
              <Text style={styles.rowDateText}>{this.props.date}</Text>
            </View>}
          </View>
          <View style={styles.rowTitleIcon}>
            {this.props.unread != 0 && <Icon name={'notification'} color={'black'} badgeCount={this.props.unread} />}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default Row;