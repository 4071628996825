import React from 'react';
import { createAppContainer, StackActions, NavigationActions } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/icon';
import HomeScreen from './homeScreen';
import ProductListing from './productListing';
import PurchaseScreen from './purchaseScreen';
import OrderListing from './orderListing';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import MeScreen from './aboutMeScreen';
import QuotationScreen from './quotationScreen';
import NotificationScreen from './notificationScreen';
import CompanyDetailScreen from './companyDetailScreenNonEditable';
import MouldingGuideScreen from './mouldingGuideScreen';
import CreditLimitScreen from './creditLimitScreen';
import FeedScreen from './feedScreen';
import MessageListing from './messageListing';

const MeStack = createStackNavigator({
  HomeScreen: { screen: HomeScreen },
  MeScreen: { screen: MeScreen },
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen },
  CompanyDetailScreen: { screen: CompanyDetailScreen },
  MouldingGuideScreen: { screen: MouldingGuideScreen },
  CreditLimitScreen: { screen: CreditLimitScreen },
}, {
  headerMode: 'none',
  initialRouteName: 'HomeScreen'
});

const MessageStack = createStackNavigator({
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const TabNavigator = createBottomTabNavigator({
  News: FeedScreen,
  Home: MeStack,
  Material: ProductListing,
  Enquiry: PurchaseScreen,
  Message: MessageStack,
  Notification: NotificationScreen
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';

      if (routeName === 'Home') {
        iconName = 'home';
      } else if (routeName === 'Enquiry') {
        iconName = 'shoppingcart'
      } else if (routeName === 'Material') {
        iconName = 'database'
      } else if (routeName === 'Message') {
        iconName = 'message1';
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Notification') {
        iconName = 'bells';
      } else if (routeName === 'News') {
        iconName = 'switcher';
      }

      return <Icon name={iconName} color={color} badgeCount={badgeCount} />;
    }
  }),
  tabBarOptions: {
    activeTintColor: 'tomato',
    inactiveTintColor: 'gray',
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);