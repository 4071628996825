import React, { Component } from 'react';
import { View, Image, Text, Platform, Alert } from 'react-native';
import { Button } from 'react-native-elements';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { ScrollView } from 'react-native-gesture-handler';
import moment from 'moment';
import styles from './feedScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import Loader from '../components/loader';
import * as feedService from '../services/feedService';
import * as apis from '../configs/apis';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  feedList: array<any>;
  department: string;
  status: string;
  isLoading: boolean;
}

class FeedScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      feedList: [],
      department: '',
      status:'',
      isLoading: false
    }
  }

  async componentDidMount() {

    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
      this.setState({
        department: getCompanyResponse[0].department,
        status: getCompanyResponse[0].status
      });
    }else{
      this.setState({
        department: 'All',
        status: 'approved'
      });
    }

    let feedResponse = await feedService.getAllFeed(this.props.screenProps.token);
    
    feedResponse.sort(function (a: any, b: any) {
      return new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime();
    });
    
    if (feedResponse) {
                  
      if (this.props.screenProps.role === 'customer') {

        if(this.state.department==='chemicals')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Chemicals' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.state.department==='welding')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Welding' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.state.department==='lighting')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Lighting' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }

        let feedList: Array<any> = [];
        feedResponse.map((item: any, key: any) => (
          feedList.push({
            'title': item.caption,
            'content': 'uploadDate:'+item.uploadDate
          })
        ));
        this.setState({ feedList: feedResponse });        
      }
      else if (this.props.screenProps.role === 'management'){

        if(this.props.screenProps.department==='chemicals')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Chemicals' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.props.screenProps.department==='welding')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Welding' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }else if(this.props.screenProps.department==='lighting')
        {
          feedResponse = feedResponse.filter(function (item: any) {
            return item.createdBy === 'Management Lighting' || item.createdBy === 'Management All' || item.createdBy === 'Management Super';
          });
        }

        let feedList: Array<any> = [];
        feedResponse.map((item: any, key: any) => (
          feedList.push({
            'title': item.caption,
            'content': 'uploadDate:'+item.uploadDate
          })
        ));
        this.setState({ feedList: feedResponse });	      
      }
    }
  }

  async deleteFeed(feedId: string) {  
    let proceed: string = await this.onConfirm();
    if (proceed !== 'proceed') {
      return;
    }
    this.setState({ isLoading: true });
    await feedService.deleteFeed(this.props.screenProps.token, feedId.value);
    this.setState({ isLoading: false });
    this.props.navigation.navigate('ManagementFeedScreen');
  }
  
  onConfirm() {
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        let res: any = window.confirm(`Confirm To Proceed?`);
      
        if (res) {
          resolve('proceed');
        } else {
          resolve('cancel');
        }
      } else {
        Alert.alert('Confirm To Proceed?', '', [ 
          { text: 'Cancel', onPress: () => resolve('cancel'), style: 'cancel' }, 
          { text: 'OK', onPress: () => resolve('proceed') } 
        ], { cancelable: false } );
      }
    });
  }
  
  render() {
    return (
      <View style={styles.feedContainer}>
        <Header headerText='News' onLogout={this.props.screenProps.onLogout} />
        {this.props.screenProps.role === 'management' && <BackBar onPress={() => this.props.navigation.navigate('ManagementFeedScreen')} />}
        <ScrollView>
          {this.state.feedList.length > 0 && this.state.feedList.map((item, key) => {
            if(this.props.screenProps.role === 'management'){
            if (key % 2) {
              return (
                <View key={key} style={styles.feedRowOdd}>
                  <Text>{ item.caption }</Text>
                  <Image
                    source={{ uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/') + 1) }}
                    style={{ width: 280, height: 210 }}
                  />
                  <Text>Posted on { moment(new Date(item.uploadDate)).format('MMMM Do YYYY, h:mm:ss a') }</Text>
                  <Text>Posted By { item.createdBy }</Text>
			      
                  <View style={styles.deleteButton}><Button title="Delete"  onPress={() =>this.deleteFeed({value: item._id})} /></View>
                </View>                
              );
            } else {
              return (
                <View key={key} style={styles.feedRowEven}>
                  <Text>{ item.caption }</Text>
                  <Image
                    source={{ uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/') + 1) }}
                    style={{ width: 280, height: 210 }}
                  />
                  <Text>Posted on { moment(new Date(item.uploadDate)).format('MMMM Do YYYY, h:mm:ss a') }</Text>
                  <Text>Posted By { item.createdBy }</Text>

                  <View style={styles.deleteButton}><Button title="Delete"  onPress={() =>this.deleteFeed({value: item._id})} /></View>
                </View>
              );
            }
          }else{
            if (key % 2) {
              return (
                <View key={key} style={styles.feedRowOdd}>
                  <Text>{ item.caption }</Text>
                  <Image
                    source={{ uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/') + 1) }}
                    style={{ width: 280, height: 210 }}
                  />
                  <Text>Posted on { moment(new Date(item.uploadDate)).format('MMMM Do YYYY, h:mm:ss a') }</Text>
                  <Text>Posted By {item.createdBy}</Text>
                </View>                
              );
            } else {
              return (
                <View key={key} style={styles.feedRowEven}>
                  <Text>{ item.caption }</Text>
                  <Image
                    source={{ uri: apis.STATIC_PATH + item.photoURI.substring(item.photoURI.lastIndexOf('/') + 1) }}
                    style={{ width: 280, height: 210 }}
                  />
                  <Text>Posted on { moment(new Date(item.uploadDate)).format('MMMM Do YYYY, h:mm:ss a') }</Text>
                  <Text>Posted By {item.createdBy}</Text>
                </View>
              );
            }
          }
          })}
        </ScrollView>
        <Loader isLoading={this.state.isLoading}/>
      </View>
    );
  }
}

export default FeedScreen;
