import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './managementOrderListing.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import BackBar from '../components/backBar';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class CustomerListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    let companyListingResponse: any = await CompanyService.getCompanyAll(this.props.screenProps.token);

    if (companyListingResponse) {
      companyListingResponse = companyListingResponse.filter(function (item: any) {
        return item.type === 'customer';
      });
      
      if (this.props.screenProps.department !== 'all' && this.props.screenProps.department !== 'super') {
        let departmentToFilter: string = this.props.screenProps.department;
        
        companyListingResponse = companyListingResponse.filter(function (item: any) {
          return item.department === 'all' || item.department === departmentToFilter;
        });
      }
        
      let customerListing: Array<any> = [];
      companyListingResponse.map((item: any, key: any) => (
        customerListing.push({
          'title': item.name,
          'content': '',
          'buttonText': 'SELECT',
          'buttonOnPress': () => this.onSelect(item.name)
        })
      ));
      
      customerListing.sort((a, b) => a.title.localeCompare(b.title));

      this.setState({ customerListing: customerListing });
    }
  }

  onSelect(orderBy: string) {
    this.props.navigation.navigate('OrderListing', {customerEmail: orderBy});
  }

  render() {
    return (
      <View style={styles.customerContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Order Listing' onLogout={this.props.screenProps.onLogout} />
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>Your customer have not order yet!</Text>}
        {this.state.customerListing.length > 0 && <CardList dataList={this.state.customerListing} />}
      </View>
    );
  }
}

export default CustomerListing;