import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  form: {
    width: 250,
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  scrollViewContainer: { 
    flexGrow: 1, 
    justifyContent: "center" 
  },
  formContainer: {
    flex: 2
  },
  button: {
    width: 200,
    paddingTop: 10,
    alignSelf: 'center'
  },
  errorMessage: {
    color: 'red',
    alignSelf: 'center'
  },
  inputContainer: {
    paddingBottom: 20
  },
  pickerContainer: {
    paddingLeft: 10,
    paddingBottom: 20
  },
  picker: {
    height: 50,
    width: 200
  },
  labelText: {
    fontSize: 16,
    color: '#999999',
    fontWeight: 'bold'
  }
});

export default styles;