import React, { Component } from 'react';
import { View, Text, KeyboardAvoidingView } from 'react-native';
import { Input, Button } from 'react-native-elements'
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { ScrollView } from 'react-native-gesture-handler';
import { Table, TableWrapper, Row } from 'react-native-table-component';
import styles from './companyDetailScreenNonEditable.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  companyId: string;
  type: string;
  customer: string;
  department: string;
  registrationNumber: string;
  address: string;
  contactNumber: string;
  email: string;
  salesVerification: number;
  businessNature: string;
  turnover: boolean;
  numberOfWorker: string;
  numberOfProductionLine: boolean;
  userTier: number;
  status: string;
  registerDate: string;
}

class CompanyDetailScreenNonEditable extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      companyId: '',
      type: '',
      name: '',
      department: '',
      registrationNumber: '',
      address: '',
      contactNumber: '',
      email: '',
      salesVerification: '',
      businessNature: '',
      turnover: '',
      numberOfWorker: '',
      numberOfProductionLine: 0,
      userTier: 0,
      status: '',
      registerDate: ''
    }
  }

  async componentDidMount() {
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
		    this.setState({
		      companyId: getCompanyResponse[0]._id,
		      type: getCompanyResponse[0].type,
        name: getCompanyResponse[0].name,
        department: getCompanyResponse[0].department,
		      registrationNumber: getCompanyResponse[0].registrationNumber,
		      address: getCompanyResponse[0].address,
		      contactNumber: getCompanyResponse[0].contactNumber,
		      email: getCompanyResponse[0].email,
		      salesVerification: getCompanyResponse[0].salesVerification,
		      businessNature: getCompanyResponse[0].businessNature,
		      turnover: getCompanyResponse[0].turnover,
		      numberOfWorker: getCompanyResponse[0].numberOfWorker,
		      numberOfProductionLine: getCompanyResponse[0].numberOfProductionLine,
		      userTier: getCompanyResponse[0].userTier,
		      status: getCompanyResponse[0].status,
		      registerDate: getCompanyResponse[0].registerDate
      });
    }
  }

  render() {
    return (
      <View style={styles.companyContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Company' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('MeScreen')} />
        <KeyboardAvoidingView style={styles.companyContainer} behavior="padding" enabled>
		        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
		          <Table>
		            <Row data={['Company Type', this.state.type]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
		            <Row data={['Company Name', this.state.name]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Department', this.state.department]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Company Registration Number', this.state.registrationNumber]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
		            <Row data={['Address', this.state.address]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Contact Number', this.state.contactNumber]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
		            <Row data={['Email', this.state.email]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
              <Row data={['Business Nature', this.state.businessNature]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Company Turn Over', this.state.turnover]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
		            <Row data={['Number Of Worker', this.state.numberOfWorker]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
              <Row data={['Number Of Production Line', this.state.numberOfProductionLine.toString()]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />              
              <Row data={['Verify By Sales & Date', this.state.salesVerification]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
            </Table>
		        </ScrollView>
		      </KeyboardAvoidingView>
      </View>
    );
  }
}

export default CompanyDetailScreenNonEditable;
