import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styles from './productListing.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import BackBar from '../components/backBar';
import * as ProductService from '../services/productService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  productList: Array<any>;
  filter: Array<any>;
  optionDisplayed: Array<any>;
  mode: string;
}

class ProductListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      productList: [],
      filter: [],
      optionDisplayed: [],
      mode: 'category'
    }

    this.onSelect = this.onSelect.bind(this);
    this.onBack = this.onBack.bind(this);
    this.regenerateList = this.regenerateList.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  async componentDidMount() {
    let productListingResponse: any = [];
    productListingResponse = await ProductService.getProductByStatus(this.props.screenProps.token, 'active');

    if (this.props.navigation.getParam('mode', '') === 'brand') {
      this.setState({ mode: 'brand' });
    }   

    if (productListingResponse) {
		    let productListing: Array<any> = productListingResponse.map(a => Object.assign({}, a));
		    this.setState({ productList: productListing }, () => this.regenerateList());
    }
  }

  onSelect(key: string, value: string) {
    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));
    filter.push({ key: key, value: value });
    this.setState({ filter: filter }, () => this.regenerateList());
  }

  onBack() {
    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));
    filter.pop();
    this.setState({ filter: filter }, () => this.regenerateList());
  }
  
  regenerateList() {
    let option: Array<string>;
    let key: string;
    let productListing: Array<any> = this.state.productList.map(a => Object.assign({}, a));
    let filter: Array<any> = this.state.filter.map(a => Object.assign({}, a));
    
    filter.map((item, index) => {
      productListing = productListing.filter(function(product: any) {
        return product[item.key] === item.value;
      });
    });
    
    if (filter.length === 0) {    
		    if (this.state.mode === 'brand') {
		      option = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'brand';
		    } else {
		      option = productListing.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'category';
		    }
    } else {
      let lastFilter: string = filter[filter.length - 1].key;
      
      if (lastFilter === 'brand' && this.state.mode === 'brand') {
        option = productListing.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'category';
      } else if (lastFilter === 'category') {
        option = productListing.map(item => item.subCategory).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'subCategory';
      } else if (lastFilter === 'subCategory') {
        option = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'material';
      } else if (lastFilter === 'material') {
        option = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'subMaterial';
      } else if (lastFilter === 'subMaterial' && this.state.mode === 'category') {
        option = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'brand';
      } else if ((lastFilter === 'subMaterial' && this.state.mode === 'brand') || (lastFilter === 'brand' && this.state.mode === 'category')) {
        option = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
		      key = 'grade';
      } else if (lastFilter === 'grade') {
        let params: any = {};
        filter.map((item, index) => { params[item.key] = item.value });
        this.setState({ filter: [] });
        this.props.navigation.navigate('Enquiry', params);
        return;
      } 
    }
    
    option.sort();
		    
		  let optionDisplayed: Array<any> = [];
    option.map((item: any, index: any) => (
      optionDisplayed.push({
        'title': item,
        'content': '',
        'buttonText': 'SELECT',
        'buttonOnPress': () => this.onSelect(key, item)
      })
    ));
    
    this.setState({ optionDisplayed: optionDisplayed });
  }
  
  switchMode(mode: string) {
    this.setState({ mode: mode, filter: [] }, () => this.regenerateList());
  }

  render() {
    return (
      <View style={styles.productContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='All Product' onLogout={this.props.screenProps.onLogout} />
        {this.state.mode === 'category' && <TouchableOpacity style={styles.modeBar} onPress={() => this.switchMode('brand')}><Text style={styles.modeText}>Switch to Brand Mode</Text></TouchableOpacity>}
        {this.state.mode === 'brand' && <TouchableOpacity style={styles.modeBar} onPress={() => this.switchMode('category')}><Text style={styles.modeText}>Switch to Category Mode</Text></TouchableOpacity>}
        {this.state.filter.length > 0 && <BackBar onPress={this.onBack} />}
        <CardList dataList={this.state.optionDisplayed} />
      </View>
    );
  }
}

export default ProductListing;