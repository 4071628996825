import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import Icon from '../components/icon';
import OrderListing from './orderListing';
import QuotationScreen from './quotationScreen';
import ManagementOrderListing from './managementOrderListing';
import NotificationScreen from './notificationScreen';
import ManagementUserScreen from './managementUserScreen';
import CreateUserScreen from './createUserScreen';
import AllCustomerListing from './allCustomerListing';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import MessageTargetListing from './messageTargetListing';
import MessageScreen from './messageScreen';
import QuotingScreen from './supplierQuotingScreen';
import QuoteListing from './quoteListing';
import ManagementAdminScreen from './managementAdminScreen';
import ManagementFeedScreen from './managementFeedScreen';
import UploadFeedScreen from './uploadFeedScreen';
import FeedScreen from './feedScreen';
import ConsumptionListing from './consumptionListing';
import MessageListing from './messageListing';
  
const OrderStack = createStackNavigator({
  ManagementOrderListing: { screen: ManagementOrderListing },
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'ManagementOrderListing'
});

const AdminStack = createStackNavigator({
  ManagementAdminScreen: { screen: ManagementAdminScreen },
  ManagementFeedScreen: { screen: ManagementFeedScreen },
  UploadFeedScreen: { screen: UploadFeedScreen },
  FeedScreen: { screen: FeedScreen },
  ManagementUserScreen: { screen: ManagementUserScreen },
  CreateUserScreen: { screen: CreateUserScreen },
  AllCustomerListing: { screen: AllCustomerListing },
  ConsumptionListing: { screen: ConsumptionListing }
}, {
  headerMode: 'none'
});

const ProjectDevelopmentStack = createStackNavigator({
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'ProjectDevelopmentListing'
});

const MessageStack = createStackNavigator({
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const QuotingStack = createStackNavigator({
  QuotingListing: { screen: QuoteListing },
  QuotingScreen: { screen: QuotingScreen }
}, {
  headerMode: 'none'
});


const TabNavigator = createBottomTabNavigator({
  Enquiry: OrderStack,
  'Project Development': ProjectDevelopmentStack,
  Quoting: QuotingStack,
  Message: MessageStack,
  Notification: NotificationScreen,
  Admin: AdminStack
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';

      if (routeName === 'Enquiry') {
        iconName = 'shoppingcart'
      } else if (routeName === 'Notification') {
        iconName = 'bells';
      } else if (routeName === 'Quoting') {
        iconName = 'bank';
      } else if (routeName === 'Admin') {
        iconName = 'tool';
      } else if (routeName === 'Message') {
        iconName = 'message1';
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Project Development') {
        iconName = 'team';
      } 

      return <Icon name={iconName} color={color} badgeCount={badgeCount} />;
    }
  }),
  tabBarOptions: {
    activeTintColor: 'tomato',
    inactiveTintColor: 'gray',
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);