import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './projectDevelopmentListingByCustomer.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import BackBar from '../components/backBar';
import * as ProjectService from '../services/projectService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  projectListing: Array<any>;
}

class ProjectDevelopmentListingByCustomer extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);
    
    this.state = {
      projectListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    let customerListingResponse: any = await ProjectService.getProjectDevelopmentByCustomer(this.props.screenProps.token, this.props.navigation.getParam('customerId', ''));
    
    let projectListing: Array<any> = [];
    
    if (this.props.screenProps.role !== 'supplier') {
      projectListing.push({
        'title': 'ADD NEW',
        'content': '',
        'buttonText': 'ADD',
        'buttonOnPress': () => this.onSelect('')
      });
    }
   
    if (customerListingResponse) {
      if (this.props.screenProps.role === 'supplier') {
        let companyId: string = this.props.screenProps.companyId;
        customerListingResponse = customerListingResponse.filter(function (item: any) {
          return item.supplierId === companyId;
        });
      }
      
      customerListingResponse.map((item: any, key: any) => (
        projectListing.push({
          'title': item.name,
          'content': '',
          'buttonText': 'SELECT',
          'buttonOnPress': () => this.onSelect(item._id)
        })
      ));
    }
    
    this.setState({ projectListing: projectListing });
  }

  onSelect(projectId: string) {
    this.props.navigation.navigate('ProjectDevelopmentScreen', { projectId: projectId, companyId: this.props.navigation.getParam('customerId', '') });
  }

  render() {
    return (
      <View style={styles.listingContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Project' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('ProjectDevelopmentListing')} />
        <CardList dataList={this.state.projectListing} />
      </View>
    );
  }
}

export default ProjectDevelopmentListingByCustomer;