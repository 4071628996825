import React, { Component } from 'react';
import { View, Text, Picker, Platform } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { SearchBar } from 'react-native-elements';
import RNPickerSelect from 'react-native-picker-select';
import moment from 'moment';
import styles from './consumptionListing.styles';
import Header from '../components/header';
import RowList from '../components/rowList';
import Loader from '../components/loader';
import BackBar from '../components/backBar';
import * as consumptionService from '../services/consumptionService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  consumptionList: Array<any>;
  displayData: Array<any>;
  currentKeyword: string;
  isLoading: boolean;
}

class ConsumptionListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      consumptionList: [],
      displayData: [],
      currentKeyword: '',
      isLoading: false
    }

    this.fetchData = this.fetchData.bind(this);
    this.searchFilterFunction = this.searchFilterFunction.bind(this);
    this.sortList = this.sortList.bind(this);
    this.constructData = this.constructData.bind(this);
  }

  async componentDidMount() {
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
      if (getCompanyResponse[0].allowToViewConsumption !== false) {
        await this.fetchData();
      } 
    }
  }

  async fetchData() {
    let consumptionListingResponse: any = {};
  
    this.setState({isLoading:true})
    consumptionListingResponse = await consumptionService.getConsumptionAll(this.props.screenProps.token);
    
    if (consumptionListingResponse) {
      consumptionListingResponse.sort(function (a: any, b: any) {
        return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
      }); 

      this.constructData(consumptionListingResponse);
      this.setState({ consumptionList: consumptionListingResponse, isLoading: false });
    }
  }
  
  constructData(list: Array<any>) {
    let consumptionList: Array<any> = [];
    
    list.map((item: any, key: any) => (
      consumptionList.push({
        "title": item.companyName,
        "content": "Type: " + item.type + "\nMaterial: " + item.material + "\nApplication: " + item.application + "\nConsumption: " + item.consumption + "\nRemarks: " + item.remarks,
        "date": moment(new Date(item.lastUpdated)).format('MMMM Do YYYY, h:mm:ss a'),
        "unread": false,
        "id": item._id,
        "onPress": () => {}
      })
    ));
      
    this.setState({ displayData: consumptionList });
  }
  
  searchFilterFunction(text: string) {
    let displayData: Array<any> = this.state.consumptionList.filter(item => {      
      let itemData: any = `${item.companyName.toLowerCase()}${item.type.toLowerCase()}${item.material.toLowerCase()}${item.application.toLowerCase()}${item.consumption.toLowerCase()}${item.remarks.toLowerCase()}`;
      let keyword = text.toLowerCase();
      
      return itemData.indexOf(keyword) > -1;    
    });
  
    this.constructData(displayData);  
    this.setState({ currentKeyword: text });
  }
  
  sortList(sortBy: string) {
    let consumptionList: Array<any> = this.state.consumptionList;
    
    if (sortBy === 'dateDesc') {
      consumptionList.sort(function (a: any, b: any) {
        return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
      });
    } else if (sortBy === 'dateAsc') {
      consumptionList.sort(function (a: any, b: any) {
        return new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
      });
    } else if (sortBy === 'type') {
      consumptionList.sort(function (a: any, b: any) {
        return a.type.localeCompare(b.type);
      });
    } else if (sortBy === 'material') {
      consumptionList.sort(function (a: any, b: any) {
        return a.material.localeCompare(b.material);
      });
    } else if (sortBy === 'application') {
      consumptionList.sort(function (a: any, b: any) {
        return a.application.localeCompare(b.application);
      });
    } else if (sortBy === 'companyName') {
      consumptionList.sort(function (a: any, b: any) {
        return a.companyName.localeCompare(b.companyName);
      });
    } else if (sortBy === 'consumption') {
      consumptionList.sort(function (a: any, b: any) {
        return a.consumption.localeCompare(b.consumption);
      });
    }
    
    this.setState({ consumptionList: consumptionList });
    this.searchFilterFunction(this.state.currentKeyword);
  }
  
  render() {
    return (
      <View style={styles.consumptionContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Consumption' onLogout={this.props.screenProps.onLogout} />
        {this.props.screenProps.role === 'supplier' && <BackBar onPress={() => this.props.navigation.navigate('HomeScreen')} />}
        {this.props.screenProps.role === 'management' && <BackBar onPress={() => this.props.navigation.navigate('ManagementAdminScreen')} />}
        <SearchBar placeholder="Search Keyword Here..." lightTheme round onChangeText={text => this.searchFilterFunction(text)} autoCorrect={false} value={this.state.currentKeyword} containerStyle={styles.searchBar} />    
        <View>
          { Platform.OS === 'ios' && <RNPickerSelect onValueChange={sortBy => this.sortList(sortBy)} items={[{label: 'Date Descending', value: 'dateDesc'}, {label: 'Date Ascending', value: 'dateAsc'}, {label: 'Type', value: 'type'}, {label: 'Material', value: 'material'}, {label: 'Application', value: 'application'}, {label: 'Company Name', value: 'companyName'}, {label: 'Consumption', value: 'consumption'}]} /> }
          { Platform.OS !== 'ios' && <Picker selectedValue='' onValueChange={sortBy => this.sortList(sortBy)}>
              <Picker.Item label='Date Descending' value='dateDesc' />
              <Picker.Item label='Date Ascending' value='dateAsc' />
              <Picker.Item label='Type' value='type' />
              <Picker.Item label='Material' value='material' />
              <Picker.Item label='Application' value='application' />
              <Picker.Item label='Company Name' value='companyName' />
              <Picker.Item label='Consumption' value='consumption' />
            </Picker>
          }
        </View>
        {this.state.displayData.length === 0 && <Text style={styles.emptyMessage}>No consumption!</Text>}
        {this.state.displayData.length > 0 && <RowList dataList={this.state.displayData} />}
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default ConsumptionListing;
