import React, { Component } from 'react';
import { View } from 'react-native';
import Icons from 'react-native-vector-icons/AntDesign';
import styles from './icon.styles';
import IconBadge from './iconBadge';

interface CustomInputProps {
  name: string;
  size: number;
  color: string;
  badgeCount: number;
}

class Icon extends Component<CustomInputProps> {
  static defaultProps = {
    size: 25
  }
  
  render() {
    return (
      <View style={styles.iconContainer}>
        <Icons name={this.props.name} size={this.props.size} color={this.props.color} />
        {this.props.badgeCount > 0 && <IconBadge badgeCount={this.props.badgeCount} />}
      </View>
    );
  }
}

export default Icon;