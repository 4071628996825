import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  feedContainer: {
    flex: 1
  },
  feedRowOdd: {
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 50,
    backgroundColor: 'white' 
  },
  feedRowEven: {
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 50,
    backgroundColor: '#F2F1F1' 
  },
  deleteButton: {
    width:220,
    paddingTop: 15,
    paddingLeft: 50
  }
});

export default styles;
