import React, { Component } from 'react';
import { View, Image, Text, Platform } from 'react-native';
import styles from './loginScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import * as authService from '../services/authService';
import * as notificationService from '../services/notificationService';
import * as messageService from '../services/messageService';

interface CustomInputProps {
  onLogin: Function;
  onLogout: Function;
  switchResetPassword: Function;
}

interface CustomInputStates {
  formInput: Array<any>;
  email: string;
  password: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
}

class LoginScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      email: '',
      password: '',
      haveError: false,
      errorMessage: '',
      isLoading: false
    }

    this.generateForm = this.generateForm.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.switchResetPassword = this.switchResetPassword.bind(this);
  }

  componentDidMount() {
    this.generateForm();
  }

  generateForm() {
    // TODO: this method will caused re-render everytime user key in a character. In future will need change if performance is affected. Temporary do not need to change as of now
    let formInput: Array<any> = [
      { placeholder: 'Username', onChangeText: this.onChangeEmail, value: this.state.email },
      { placeholder: 'Password', onChangeText: this.onChangePassword, secureTextEntry: true, value: this.state.password }
    ];

    this.setState({ formInput: formInput });
  }

  onChangeEmail(input: string) {
    this.setState({ email: input }, () => this.generateForm());
  }

  onChangePassword(input: string) {
    this.setState({ password: input }, () => this.generateForm());
  }

  async onLogin() {
    this.setState({ isLoading: true });
    const authServiceResponse: any = await authService.login(this.state.email, this.state.password);

    if (authServiceResponse) {
      if (Platform.OS != "web") {
        await notificationService.registerForPushNotificationsAsyn(authServiceResponse.token);
      }
      
      let unreadCount: any = await messageService.getUnreadCount(authServiceResponse.token);
      this.setState({ isLoading: false });
      
      this.props.onLogin(authServiceResponse.token, authServiceResponse.role, authServiceResponse.id, authServiceResponse.companyId || '', authServiceResponse.tier || '', unreadCount.unreadCount, authServiceResponse.department || '');
    } else {
      this.setState({ haveError: true, isLoading: false });
      this.setState({ errorMessage: 'Invalid email/password' });
    }
  }
  
  onLogout() {
    this.props.onLogout();
  }
  
  switchResetPassword() {
    this.props.switchResetPassword();
  }

  render() {
    return (
      <View style={styles.loginContainer}>
        <Header headerText='Login' onLogout={this.onLogout} />
        <View style={styles.formContainer}>
          <Image
            style={styles.logo}
            source={require('./../../assets/logo_ep.png')}
          />
        </View>
        <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Login'} buttonOnPress={this.onLogin} />
        <View style={styles.resetContainer}><Text style={styles.resetText} onPress={this.switchResetPassword}>Reset Password?</Text></View>
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default LoginScreen;