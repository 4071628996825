import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  iconContainer: {
    width: 24,
    height: 24,
    margin: 5
  }
});

export default styles;