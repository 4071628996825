import axios from 'axios';
import * as apis from '../configs/apis';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import { Platform } from 'react-native';

export const getNotification = async (token: string) => {
  try {
    const getNotificationResponse: any = await axios.get(apis.NOTIFICATION_API + '/notification', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getNotificationResponse.data;
  } catch (err) {
    console.log(`Error returned from GET notification API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const readNotification = async (token: string, notificationId: string) => {
  try {
    await axios.put(apis.NOTIFICATION_API + '/notification/' + notificationId + '/read', {}, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from PUT notification read API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const registerForPushNotificationsAsyn = async (token: string) => {
  const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
  let finalStatus: string = status;

  if (finalStatus !== 'granted') {
    return;
  }

  if (Platform.OS === 'android') {
    Notifications.createChannelAndroidAsync('chat-messages', {
      name: 'Chat messages',
      sound: true,
    });
  }

  let expoToken: string = await Notifications.getExpoPushTokenAsync();
  await axios.post(apis.NOTIFICATION_API + '/expoToken', {expoToken: expoToken}, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
}

export const postNotification = async (token:string, userId: string, title: string, message: string, destination: string, destinationId: string) => {
  try {
    await axios.post(apis.NOTIFICATION_API + '/notification', { 'userId': userId, 'title': title, 'message': message, 'destination': destination, 'destinationId': destinationId }, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
  } catch (err) {
    console.log(`Error returned from POST notification API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}