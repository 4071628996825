import { StyleSheet, StatusBar, Platform } from 'react-native';

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'black',
    height: 80,
    paddingTop: (Platform.OS === 'ios') ? 20 : StatusBar.currentHeight,
    paddingLeft: 20,
    flexDirection: 'row'
  },
  headerText: {
    color: 'white',
    fontSize: 30
  },
  headerTextContainer: {
    flex: 3,
    paddingTop: (Platform.OS === 'web') ? 20 : 0,
  },
  headerLogoutContainer: {
    flex: 2,
    paddingTop: (Platform.OS === 'web') ? 20 : 5,
    paddingRight: 5
  }
});

export default styles;