import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from './notificationScreen.styles';
import Header from '../components/header';
import RowList from '../components/rowList';
import * as notificationService from '../services/notificationService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  notifications: Array<any>;
}

class NotificationScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      notifications: []
    }
    
    this.onWillFocus = this.onWillFocus.bind(this);
    this.getData = this.getData.bind(this);
  }
  
  focusSubscription = null;

  componentDidMount() {
    this.focusSubscription = this.props.navigation.addListener('willFocus', this.onWillFocus);
  }
  
  componentWillUnmount() { 
    this.focusSubscription && this.focusSubscription.remove();
    this.focusSubscription = null; 
  }
     
  async onWillFocus(payload) { 
    await this.getData();
  }

  async getData() {
    let notificationResponse: any = await notificationService.getNotification(this.props.screenProps.token);

    if (notificationResponse[0]) {
      notificationResponse[0].notifications.sort(function (a: any, b: any) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      let notifications: Array<any> = [];
      notificationResponse[0].notifications.map((item: any, key: any) => (
        notifications.push({
          "title": item.title,
          "content": item.message,
          "date": moment(new Date(item.createdAt)).format('MMMM Do YYYY, h:mm:ss a'),
          "unread": item.unread,
          "id": item._id,
          "onPress": () => this.onRead(item)
        })
      ));

      this.setState({ notifications: notifications });
    }
  }

  onRead(notification: any) {
    notificationService.readNotification(this.props.screenProps.token, notification._id);
    
    let notifications: Array<any> = this.state.notifications;
    notifications.map((item: any, key:any) => {
      if (item.id === notification._id) {
        item.unread = false;
      }
    });

    this.setState({ notifications: notifications });
    this.props.navigation.navigate(notification.destination, notification.destinationId);
  }

  render() {
    return (
      <View style={styles.notificationContainer}>
        <Header headerText={'Notification'} onLogout={this.props.screenProps.onLogout} />
        {this.state.notifications.length === 0 && <Text style={styles.emptyMessage}>You have no notification!</Text>}
        {this.state.notifications.length > 0 && <RowList dataList={this.state.notifications} />}
      </View>
    );
  }
}

export default NotificationScreen;
