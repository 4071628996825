import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { Table, TableWrapper, Row } from 'react-native-table-component';
import styles from './creditLimitScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  creditLimit: string;
  debtorAgeing: string;
}

class CreditLimitScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      creditLimit: '',
      debtorAgeing: ''
    }
  }

  async componentDidMount() {
    let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
    
    if (getCompanyResponse[0]) {
		    this.setState({
		      creditLimit: getCompanyResponse[0].creditLimit,
		      debtorAgeing: getCompanyResponse[0].debtorAgeing
      });
    }
  }

  render() {
    return (
      <View style={styles.companyContainer}>
        <Header headerText='Company' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('MeScreen')} />
        <Table>
          <Row data={['Credit Limit', this.state.creditLimit]} flexArr={[1,1]} style={[styles.tableRow]} textStyle={styles.tableText} />
          <Row data={['Debtor Ageing', this.state.debtorAgeing]} flexArr={[1,1]} style={[styles.tableRow, {backgroundColor: '#F2F2F2'}]} textStyle={styles.tableText} />
        </Table>
      </View>
    );
  }
}

export default CreditLimitScreen;
