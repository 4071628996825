import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  productContainer: {
    flex: 1
  },
  modeBar: {
    backgroundColor: 'green',
    height: 50,
  },
  modeText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 25,
    paddingTop: 5
  }
});

export default styles;