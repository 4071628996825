import React, { Component } from 'react';
import { View } from 'react-native';
import moment from 'moment';
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './projectDevelopmentScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import BackBar from '../components/backBar';
import * as ProjectService from '../services/projectService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  formInput: Array<any>;
  customer: string;
  name: string;
  application: string;
  consumption: string;
  existingGrade: string;
  proposeGrade: string;
  status: string;
  remarks: string;
  lastUpdated: string;
  supplier: string;
  supplierRemarks: string;
  supplierList: Array<any>;
  isLoading: boolean;
  haveError: boolean;
  errorMessage: boolean;
}

class ProjectDevelopmentScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      customer: '',
      name: '',
      application: '',
      consumption: '',
      existingGrade: '',
      proposeGrade: '',
      status: '',
      remarks: '',
      lastUpdated: '',
      supplier: '',
      supplierRemarks: '',
      supplierList: [],
      isLoading: false,
      haveError: false,
      errorMessage: ''
    }

    this.onChangeCustomer = this.onChangeCustomer.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeApplication = this.onChangeApplication.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.onChangeConsumption = this.onChangeConsumption.bind(this);
    this.onChangeExistingGrade = this.onChangeExistingGrade.bind(this);
    this.onChangeProposeGrade = this.onChangeProposeGrade.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangeSupplierRemarks = this.onChangeSupplierRemarks.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    let supplierList: any = await CompanyService.getSupplierAll(this.props.screenProps.token);
    supplierList = supplierList.map(item => item.name);
    supplierList.sort();
   
    if (this.props.navigation.getParam('projectId', '')) {
      let projectDevelopmentData: any = await ProjectService.getProjectDevelopmentById(this.props.screenProps.token, this.props.navigation.getParam('projectId', ''));
      
      this.setState({
        customer: projectDevelopmentData.companyId,
        name: projectDevelopmentData.name,
        application: projectDevelopmentData.application,
        consumption: projectDevelopmentData.consumption,
        existingGrade: projectDevelopmentData.existingGrade,
        proposeGrade: projectDevelopmentData.proposeGrade,
        status: projectDevelopmentData.status,
        remarks: projectDevelopmentData.remarks,
        lastUpdated: moment(new Date(projectDevelopmentData.lastUpdated)).format('MMMM Do YYYY, h a'),
        supplier: projectDevelopmentData.supplierId,
        supplierRemarks: projectDevelopmentData.supplierRemarks,
        supplierList: supplierList
      }, () => { this.generateForm(); });
    } else {
      this.setState({
        customer: this.props.navigation.getParam('companyId', ''),
        supplierList: supplierList
      }, () => { this.generateForm(); });
    }
  }

  generateForm() {
    let formInput: Array<any> = [];

    if (this.props.screenProps.role === 'supplier') {
      formInput = [
        { placeholder: 'Supplier', onChangeText: this.onChangeSupplier, value: this.state.supplier, disabled: true },
        { placeholder: 'Supplier Remarks', onChangeText: this.onChangeSupplierRemarks, value: this.state.supplierRemarks },        
        { placeholder: 'Customer', onChangeText: this.onChangeCustomer, value: this.state.customer, disabled: true },
        { placeholder: 'Name', onChangeText: this.onChangeName, value: this.state.name, disabled: true },
        { placeholder: 'Application', onChangeText: this.onChangeApplication, value: this.state.application, disabled: true },
        { placeholder: 'Consumption', onChangeText: this.onChangeConsumption, value: this.state.consumption, disabled: true },
        { placeholder: 'Existing Grade', onChangeText: this.onChangeExistingGrade, value: this.state.existingGrade, disabled: true },
        { placeholder: 'Propose Grade', onChangeText: this.onChangeProposeGrade, value: this.state.proposeGrade, disabled: true },
        { placeholder: 'Status', onChangeText: this.onChangeStatus, value: this.state.status, disabled: true },
      ];
    } else {
      formInput = [
        { placeholder: 'Supplier', onChangeText: this.onChangeSupplier, value: this.state.supplier, option: this.state.supplierList, inputType: 'picker' },
        { placeholder: 'Supplier Remarks', onChangeText: this.onChangeSupplierRemarks, value: this.state.supplierRemarks, disabled: true },        
        { placeholder: 'Customer', onChangeText: this.onChangeCustomer, value: this.state.customer, disabled: true },
        { placeholder: 'Name', onChangeText: this.onChangeName, value: this.state.name },
        { placeholder: 'Application', onChangeText: this.onChangeApplication, value: this.state.application },
        { placeholder: 'Consumption', onChangeText: this.onChangeConsumption, value: this.state.consumption },
        { placeholder: 'Existing Grade', onChangeText: this.onChangeExistingGrade, value: this.state.existingGrade },
        { placeholder: 'Propose Grade', onChangeText: this.onChangeProposeGrade, value: this.state.proposeGrade },
        { placeholder: 'Status', onChangeText: this.onChangeStatus, value: this.state.status },
      ];
    }
    
    if (this.props.screenProps.role === 'management') {
      formInput.push({ placeholder: 'Remarks', onChangeText: this.onChangeRemarks, value: this.state.remarks });
    } else {
      formInput.push({ placeholder: 'Remarks', onChangeText: this.onChangeRemarks, value: this.state.remarks, disabled: true });
    }
    
    if (this.state.lastUpdated) {
      formInput.push({ placeholder: 'Last Update', value: this.state.lastUpdated, disabled: true });
    } else {
      formInput.push({ placeholder: 'Last Update', value: 'N/A', disabled: true });
    }
    
    this.setState({ formInput: formInput });
  }

  onChangeCustomer(input: string) {
    this.setState({ customer: input }, () => this.generateForm());
  }
  
  onChangeName(input: string) {
    this.setState({ name: input }, () => this.generateForm());
  }
  
  onChangeApplication(input: string) {
    this.setState({ application: input }, () => this.generateForm());
  }
  
  onChangeConsumption(input: string) {
    this.setState({ consumption: input }, () => this.generateForm());
  }
  
  onChangeExistingGrade(input: string) {
    this.setState({ existingGrade: input }, () => this.generateForm());
  }
  
  onChangeProposeGrade(input: string) {
    this.setState({ proposeGrade: input }, () => this.generateForm());
  }
  
  onChangeStatus(input: string) {
    this.setState({ status: input }, () => this.generateForm());
  }
  
  onChangeRemarks(input: string) {
    if (this.props.screenProps.role === 'management') {
      this.setState({ remarks: input }, () => this.generateForm());
    }
  }
  
  onChangeSupplier(input: string) {
    this.setState({ supplier: input }, () => this.generateForm());
  }
  
  onChangeSupplierRemarks(input: string) {
    this.setState({ supplierRemarks: input }, () => this.generateForm());
  }

  async onSubmit() {
    this.setState({ isLoading: true });
    let success: boolean = true;
    let projectDetails: any = { 'companyId': this.state.customer, 'name': this.state.name, 'application': this.state.application, 'consumption': this.state.consumption, 'existingGrade': this.state.existingGrade, 'proposeGrade': this.state.proposeGrade, 'status': this.state.status, 'remarks': this.state.remarks, 'lastUpdated': new Date(), 'supplierId': this.state.supplier, 'supplierRemarks': this.state.supplierRemarks }
    
    if (this.props.navigation.getParam('projectId', '')) {
      success = await ProjectService.postProjectDevelopmentById(this.props.screenProps.token, this.props.navigation.getParam('projectId', ''), projectDetails);
    } else {
      success = await ProjectService.postProjectDevelopment(this.props.screenProps.token, projectDetails);
    }
    
    this.setState({ isLoading: false });

    if (success) {
      this.setState({
        haveError: true,
        errorMessage: 'Data Saved!'
      });
    } else {
      this.setState({
        haveError: true,
        errorMessage: 'An error has occured! Please retry again!'
      });
    }
  }

  render() {
    return (
      <View style={styles.projectDevelopmentContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Project' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('ProjectDevelopmentListing')} />
        <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Save'} buttonOnPress={ () => this.onSubmit() } />
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default ProjectDevelopmentScreen;
