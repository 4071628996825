import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  companyContainer: {
    flex: 1
  },
  tableRow: { 
    height: 60, 
    backgroundColor: 'white' 
  },
  tableText: { 
    textAlign: 'center', 
    fontWeight: '100' 
  }
});

export default styles;