import axios from 'axios';
import * as apis from '../configs/apis';

export const getOrder = async (token: string) => {
  try {
    const getOrderResponse: any = await axios.get(apis.ORDER_API + '/order', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getOrderResponse.data;
  } catch (err) {
    console.log(`Error returned from GET order API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getOrderById = async (token: string, orderId: string) => {
  try {
    const getOrderResponse: any = await axios.get(apis.ORDER_API + '/order/' + orderId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getOrderResponse.data;
  } catch (err) {
    console.log(`Error returned from GET order API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getOrderByCompany = async (token: string, companyId: string) => {
  try {
    const getOrderResponse: any = await axios.get(apis.ORDER_API + '/order/orderBy/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getOrderResponse.data;
  } catch (err) {
    console.log(`Error returned from GET order API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postOrder = async (token: string, orderDetails: any) => {
  try {
    await axios.post(apis.ORDER_API + '/order', orderDetails, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST order API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postQuotation = async (token: string, quotation: any) => {
  try {
    await axios.post(apis.ORDER_API + '/quotation', quotation, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST quotation API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const putStatus = async (token: string, orderId: string, status: string) => {
  try {
    await axios.put(apis.ORDER_API + '/order/' + orderId + "/status/" + status, {}, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from PUT status API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}