import React, { Component } from 'react';
import { View, Alert, Platform } from 'react-native';
import { NavigationActions, NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import moment from 'moment';
import styles from './purchaseScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import * as OrderService from '../services/orderService';
import * as ProductService from '../services/productService';
import * as CompanyService from '../services/companyService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  formInput: Array<any>;
  brand: string;
  material: string;
  subMaterial: string;
  grade: string;
  colour: string;
  quantity: number;
  deliveryDate: string;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
  freeTextInput: boolean;
  productList: Array<any>;
}

class PurchaseScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      brand: '',
      material: '',
      subMaterial: '',
      grade: '',
      colour: '',
      quantity: 0,
      deliveryDate: '',
      haveError: false,
      errorMessage: '',
      isLoading: false,
      freeTextInput: false,
      productList: []
    }

    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeMaterial = this.onChangeMaterial.bind(this);
    this.onChangeSubMaterial = this.onChangeSubMaterial.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);
    this.onChangeColour = this.onChangeColour.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
    this.onPurchase = this.onPurchase.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  async componentDidMount() {
    let productListingResponse: any = [];
    let productListing: Array<any> = [];
    this.setState({ isLoading: true });
    productListingResponse = await ProductService.getProductByStatus(this.props.screenProps.token, 'active');
    this.setState({ isLoading: false });
    
    if (productListingResponse) {
		    productListing = productListingResponse.map(a => Object.assign({}, a));
    } 
  
    this.setState({
      brand: this.props.navigation.getParam('brand', ''),
      material: this.props.navigation.getParam('material', ''),
      subMaterial: this.props.navigation.getParam('subMaterial', ''),
      grade: this.props.navigation.getParam('grade', ''),
      colour: this.props.navigation.getParam('colour', ''),
      quantity: parseInt(this.props.navigation.getParam('quantity', 0)),
      deliveryDate: this.props.navigation.getParam('deliveryDate', ''),
      productList: productListing
    }, () => { this.generateForm() });
  }

  generateForm() {
    let formInput: Array<any> = [];
    
    formInput.push({ placeholder: 'Customer', onChangeText: () => {}, value: this.props.screenProps.companyId, disabled: true });
    
    if (this.state.freeTextInput) { 
      formInput.push(
        { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand },
        { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material },
        { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial },
        { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade },
        { placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour },
        { placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'numeric', value: this.state.quantity.toString() },
        { placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.deliveryDate }
      );
    } else {
      let productListing: Array<any> = this.state.productList.map(a => Object.assign({}, a));    
      let brandOption: Array<string> = productListing.map(item => item.brand).filter((value, index, self) => self.indexOf(value) === index);
      let materialOption: Array<string> = [];
      let subMaterialOption: Array<string> = [];
      let gradeOption: Array<string> = [];
      let colourOption: Array<string> = ['natural', 'black', 'white', 'other'];
      
      brandOption.push('Others');
      brandOption.sort();
      
      if (this.state.brand) {
        let brandSelected: string = this.state.brand;
        productListing = productListing.filter(function(product: any) {
          return product['brand'] === brandSelected;
        });
        
        materialOption = productListing.map(item => item.material).filter((value, index, self) => self.indexOf(value) === index);
        materialOption.sort();
        
        if (this.state.material) {
		        let materialSelected: string = this.state.material;
          productListing = productListing.filter(function(product: any) {
		          return product['material'] === materialSelected;
		        });
		        
		        subMaterialOption = productListing.map(item => item.subMaterial).filter((value, index, self) => self.indexOf(value) === index);
		        subMaterialOption.sort();
		        
		        if (this.state.subMaterial) {
				        let subMaterialSelected: string = this.state.subMaterial;
           productListing = productListing.filter(function(product: any) {
				          return product['subMaterial'] === subMaterialSelected;
				        });
				        
				        gradeOption = productListing.map(item => item.grade).filter((value, index, self) => self.indexOf(value) === index);
				        gradeOption.sort();
				      }
		      }
      }
   
      formInput.push(
        { placeholder: 'Brand', onChangeText: this.onChangeBrand, value: this.state.brand, option: brandOption, inputType: 'picker' },
        { placeholder: 'Material', onChangeText: this.onChangeMaterial, value: this.state.material, option: materialOption, inputType: 'picker' },
        { placeholder: 'Sub Material', onChangeText: this.onChangeSubMaterial, value: this.state.subMaterial, option: subMaterialOption, inputType: 'picker' },
        { placeholder: 'Grade', onChangeText: this.onChangeGrade, value: this.state.grade, option: gradeOption, inputType: 'picker' }
      );  

      if (this.state.colour === 'other') {
        formInput.push({ placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour },);
      } else {
        formInput.push({ placeholder: 'Colour', onChangeText: this.onChangeColour, value: this.state.colour, option: colourOption, inputType: 'picker' });
      }

      formInput.push({ placeholder: 'Quantity', onChangeText: this.onChangeQuantity, keyboardType: 'numeric', value: this.state.quantity.toString() });
      formInput.push({ placeholder: 'Delivery Date(DD/MM/YYYY)', onChangeText: this.onChangeDeliveryDate, value: this.state.deliveryDate });
    }

    this.setState({ formInput: formInput });
  }

  onChangeBrand(selected: string) {
    if (selected === 'Others') {
      this.setState({ freeTextInput: true }, () => this.generateForm());
    } else {
      this.setState({ brand: selected, material: '', subMaterial: '', grade: '' }, () => this.generateForm());
    }
  }

  onChangeMaterial(selected: string) {
    this.setState({ material: selected, subMaterial: '', grade: '' }, () => this.generateForm());
  }
  
  onChangeSubMaterial(selected: string) {
    this.setState({ subMaterial: selected, grade: '' }, () => this.generateForm());
  }

  onChangeGrade(selected: string) {
    this.setState({ grade: selected }, () => this.generateForm());
  }

  onChangeColour(selected: string) {
    this.setState({ colour: selected }, () => this.generateForm());
  }

  onChangeQuantity(input: string) {
    if (input) {
      this.setState({ quantity: parseInt(input.replace(/[^0-9]/g, '')) }, () => this.generateForm());
    } else {
      this.setState({ quantity: 0 }, () => this.generateForm());
    }
  }

  onChangeDeliveryDate(input: string) {
    let newInput: string = input.replace(/[^0-9]/g, '');
    
    if (newInput.length > 4) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/' + newInput.substring(4,newInput.length);
    } else if (newInput.length > 3) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,4) + '/';
    } else if (newInput.length > 1) {
      newInput = newInput.substring(0,2) + '/' + newInput.substring(2,newInput.length);
    } 
    
    this.setState({ deliveryDate: newInput }, () => this.generateForm());
  }

  async onPurchase() {
    if (this.state.quantity !== 0 && this.state.brand && this.state.material && this.state.subMaterial && this.state.grade && this.state.colour && this.state.deliveryDate) {
	     if(moment(this.state.deliveryDate, 'DD/MM/YYYY', true).isValid()) {
		      this.setState({ isLoading: true });
		      let getCompanyResponse: any = await CompanyService.getCompany(this.props.screenProps.token, this.props.screenProps.companyId);
        let department: string = getCompanyResponse[0].department;
		      let success: boolean = await OrderService.postOrder(this.props.screenProps.token, { 'brand': this.state.brand, 'material': this.state.material + ' - ' + this.state.subMaterial, 'materialType': this.state.grade, 'colour': this.state.colour, 'quantity': this.state.quantity, 'deliveryDate': this.state.deliveryDate, 'orderBy': this.props.screenProps.companyId, 'department': department });
		      this.setState({ isLoading: false });
		
		      if (success) {
		        this.props.navigation.navigate('OrderListing', { showIncompleteOnly: true });
		      } else {
		        this.setState({
		          haveError: true,
		          errorMessage: 'An error has occured. Please retry.'
		        });
		      }
      } else {
        this.setState({
		        haveError: true,
		        errorMessage: 'Invalid Date! Please input in format 31/12/2020.'
		      });
      }
    } else {
      this.setState({
        haveError: true,
        errorMessage: 'Field can not be empty!'
      });
    }
  }
  
  onConfirm() {
    if (Platform.OS === 'web') {
      let res: any = window.confirm(`Confirm To Proceed?`);
      
      if (res) {
        this.onPurchase();
      } 
    } else {
      Alert.alert('Confirm To Proceed?', '', [ 
        { text: 'Cancel', onPress: () => {}, style: 'cancel' }, 
        { text: 'OK', onPress: () => this.onPurchase() } 
      ], { cancelable: false } );
    }  
  }

  render() {
    return (
      <View style={styles.purchaseContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Enquiry' onLogout={this.props.screenProps.onLogout} />
        <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Enquire'} buttonOnPress={this.onConfirm} />
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default PurchaseScreen;
