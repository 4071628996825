import React from 'react';
import { createAppContainer } from 'react-navigation';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import Icon from '../components/icon';
import OrderListing from './orderListing';
import MessageScreen from './messageScreen';
import QuotationScreen from './quotationScreen';
import MessageTargetListing from './messageTargetListing';
import SalesScreen from './salesScreen';
import CustomerOrderListing from './customerOrderListing';
import NotificationScreen from './notificationScreen';
import ProjectDevelopmentScreen from './projectDevelopmentScreen';
import ProjectDevelopmentListing from './projectDevelopmentListing';
import ProjectDevelopmentListingByCustomer from './projectDevelopmentListingByCustomer';
import MessageListing from './messageListing';
import SalesAboutMeScreen from './salesAboutMeScreen';
import StockCheckScreen from './stockCheckScreen';

const HomeStack = createStackNavigator({
  SalesScreen: { screen: SalesScreen },
  ProjectDevelopmentListing: { screen: ProjectDevelopmentListing },
  ProjectDevelopmentListingByCustomer: { screen: ProjectDevelopmentListingByCustomer },
  ProjectDevelopmentScreen: { screen: ProjectDevelopmentScreen },
  AboutMeScreen: { screen: SalesAboutMeScreen },
  StockCheckScreen: { screen: StockCheckScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'SalesScreen'
});

const OrderStack = createStackNavigator({
  OrderListing: { screen: OrderListing },
  QuotationScreen: { screen: QuotationScreen },
  CustomerOrderListing: { screen: CustomerOrderListing }
}, {
  headerMode: 'none',
  initialRouteName: 'CustomerOrderListing'
});

const MessageStack = createStackNavigator({
  MessageListing: { screen: MessageListing },
  MessageTargetListing: { screen: MessageTargetListing },
  MessageScreen: { screen: MessageScreen }
}, {
  headerMode: 'none',
  initialRouteName: 'MessageListing'
});

const TabNavigator = createBottomTabNavigator({
  Home: HomeStack,
  Enquiry: OrderStack,
  Message: MessageStack,
  Notification: NotificationScreen
}, {
  defaultNavigationOptions: ({ screenProps, navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const routeName: string = navigation.state.routeName;

      let iconName: string = '';
      let badgeCount: number = 0;
      let color: string = tintColor || 'gray';

      if (routeName === 'Home') {
        iconName = 'home';
      } else if (routeName === 'Enquiry') {
        iconName = 'shoppingcart'
      } else if (routeName === 'Message') {
        iconName = 'message1';
        badgeCount = screenProps.unreadCount;
      } else if (routeName === 'Notification') {
        iconName = 'bells';
      }

      return <Icon name={iconName} color={color} badgeCount={badgeCount} />;
    }
  }),
  tabBarOptions: {
    activeTintColor: 'tomato',
    inactiveTintColor: 'gray',
    keyboardHidesTabBar: false
  }
});

export default createAppContainer(TabNavigator);
