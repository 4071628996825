import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import styles from './backBar.styles';
import Icon from './icon';

interface CustomInputProps {
  onPress: Function;
}

class BackBar extends Component<CustomInputProps> {
  onPress() {
    this.props.onPress();
  }

  render() {
    return (
      <TouchableOpacity style={styles.bar} onPress={this.onPress.bind(this)}>
        <View>
          <Icon name={'arrowleft'} color={'black'} badgeCount={0} />
        </View>
      </TouchableOpacity>
    );
  }
}

export default BackBar;