import axios from 'axios';
import * as apis from '../configs/apis';

export const getSales = async (token: string) => {
  try {
    const getSalesResponse: any = await axios.get(apis.USER_API + '/sales', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getSalesResponse.data;
  } catch (err) {
    console.log(`Error returned from GET sales API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const createUser = async (token: string, email: string, password: string, role: string, salesId: string) => {
  try {
    const createUserResponse: any = await axios.post(apis.USER_API + '/user', { email: email, password: password, role: role, salesId: salesId }, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return createUserResponse.data;
  } catch (err) {
    console.log(`Error returned from POST user API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getCustomer = async (token: string) => {
  try {
    const getCustomerResponse: any = await axios.get(apis.USER_API + '/customer', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCustomerResponse.data;
  } catch (err) {
    console.log(`Error returned from GET customer API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getMessageTargetList = async (token: string) => {
  try {
    const getTargetResponse: any = await axios.get(apis.USER_API + '/user/messageTarget', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getTargetResponse.data;
  } catch (err) {
    console.log(`Error returned from GET message target API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const resetPassword = async (username: string, password: string) => {
  try {
    const resetResponse: any = await axios.post(apis.USER_API + '/resetPassword', { username: username, password: password }, { headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
    return resetResponse.data;
  } catch (err) {
    console.log(`Error returned from reset password API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getManagementId = async (token: string) => {
  try {
    const getResponse: any = await axios.get(apis.USER_API + '/managementId', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getResponse.data;
  } catch (err) {
    console.log(`Error returned from GET management API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getManagementByDepartment = async (token: string, department: string) => {
  try {
    const getResponse: any = await axios.get(apis.USER_API + '/managementId/department/' + department, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getResponse.data;
  } catch (err) {
    console.log(`Error returned from GET management by department API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getUserByCompanyTier = async (token: string, companyId: string, tier: string) => {
  try {
    const getResponse: any = await axios.get(apis.USER_API + '/user/company/' + companyId + '/tier/' + tier, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getResponse.data;
  } catch (err) {
    console.log(`Error returned from GET user by company tier API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getUserById = async (token: string, userId: string) => {
  try {
    const getResponse: any = await axios.get(apis.USER_API + '/user/id/' + userId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getResponse.data;
  } catch (err) {
    console.log(`Error returned from GET user by id API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getVersion = async () => {
  try {
    const getVersionResponse: any = await axios.get(apis.USER_API + '/latestVersion', { headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
    return getVersionResponse.data;
  } catch (err) {
    console.log(`Error returned from GET version API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}