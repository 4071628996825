import React, { Component } from 'react';
import { Text, View, Button, TouchableOpacity } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './card.styles';
import Icon from './icon';

interface CustomInputProps {
  title: string;
  content: string;
  action: string;
  unread: boolean;
  onPress: Function;
}

class Card extends Component<CustomInputProps> {
  onPress() {
    this.props.onPress();
  }

  render() {
    return (
      <TouchableOpacity style={styles.card} onPress={this.onPress.bind(this)}>
        <View style={styles.cardTitle}><ScrollView>{this.props.unread && <Icon name={'notification'} color={'black'} badgeCount={1} />}<Text style={styles.cardTitleText}>{this.props.title}</Text></ScrollView></View>
        {this.props.content !== '' && <View style={styles.cardContent}><Text numberOfLines={2} ellipsizeMode='tail' style={styles.cardContentText}>{this.props.content}</Text></View>}
      </TouchableOpacity>
    );
  }
}

export default Card;