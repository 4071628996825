import React, { Component } from 'react';
import { View, Button, Image, TextInput, Platform, Alert } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import { ScrollView } from 'react-native-gesture-handler';
import * as ImagePicker from 'expo-image-picker';
import moment from 'moment';
import styles from './uploadFeedScreen.styles';
import Header from '../components/header';
import BackBar from '../components/backBar';
import Loader from '../components/loader';
import * as feedService from '../services/feedService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  feedCaption: string;
  photo: any;
  isLoading: boolean;
  createdBy: string;
}

class UploadFeedScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      feedCaption: '',
      photo: null,
      isLoading: false,
      createdBy: ''
    }
    
    this.handleChoosePhoto = this.handleChoosePhoto.bind(this);
    this.uploadFeed = this.uploadFeed.bind(this);
  }

  async componentDidMount() {
    if (this.props.screenProps.role === 'management'){

      if(this.props.screenProps.department === 'all')
      {
        this.setState({ createdBy: 'Management All'});
      }
      else if(this.props.screenProps.department === 'chemicals')
      {
        this.setState({ createdBy: 'Management Chemicals'});
      }
      else if(this.props.screenProps.department === 'welding')
      {
        this.setState({ createdBy: 'Management Welding'});
      }
      else if(this.props.screenProps.department === 'lighting')
      {
        this.setState({ createdBy: 'Management Lighting'});
      }else if(this.props.screenProps.department === 'super')
      {
        this.setState({ createdBy: 'Management Super'});
      }
    }
  }
  
  async handleChoosePhoto() {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      this.setState({ photo: result });
    }
  }
  
  async uploadFeed() {
    let proceed: string = await this.onConfirm();
    
    if (proceed !== 'proceed') {
      return;
    }
  
    this.setState({ isLoading: true });
    
    let photo: any = {};
    
    if (Platform.OS === 'web') { 
      const byteString: string = atob(this.state.photo.uri.split(',')[1]); 
      const ab: any = new ArrayBuffer(byteString.length); 
      const arr: any = new Uint8Array(ab); 
      
      for (let i = 0; i < byteString.length; i++) {
        arr[i] = byteString.charCodeAt(i); 
      }
      
      const blob: any = new Blob([arr], { type: 'image/jpeg' }); 
      photo = new File([blob], 'photo.jpg', { type: 'image/jpeg' }); 
    } else {
      photo = { 
        uri: Platform.OS === "android" ? this.state.photo.uri : this.state.photo.uri.replace("file://", ""), 
        type: 'image/jpeg', 
        name: 'photo.jpg' 
      }; 
    }
    
    let body: any = new FormData(); 
    body.append('caption', this.state.feedCaption); 
    body.append('photo', photo); 
    body.append('uploadDate', moment().format());
    body.append('createdBy', this.state.createdBy); 
    await feedService.postFeed(this.props.screenProps.token, body);
    this.setState({ isLoading: false });
    
    this.props.navigation.navigate('ManagementFeedScreen');
  }
  
  onConfirm() {
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        let res: any = window.confirm(`Confirm To Proceed?`);
      
        if (res) {
          resolve('proceed');
        } else {
          resolve('cancel');
        }
      } else {
        Alert.alert('Confirm To Proceed?', '', [ 
          { text: 'Cancel', onPress: () => resolve('cancel'), style: 'cancel' }, 
          { text: 'OK', onPress: () => resolve('proceed') } 
        ], { cancelable: false } );
      }
    });
  }

  render() {
    const placeholder = {
      label: 'Select your role...',
      value: null,
      color: '#9EA0A4',
    };
    return (
      <View style={styles.feedContainer}>
        <Header headerText='Upload Feed' onLogout={this.props.screenProps.onLogout} />
        <BackBar onPress={() => this.props.navigation.navigate('ManagementFeedScreen')} />
        <View style={styles.uploadForm}>
          <ScrollView>
            <TextInput multiline={true} numberOfLines={4} onChangeText={(text) => this.setState({feedCaption: text})} value={this.state.feedCaption} placeholder={'Write a caption for your feed here'}/>
            {this.state.photo && (
              <Image
                source={{ uri: this.state.photo.uri }}
                style={{ width: 280, height: 210 }}
              />
            )}
            <View style={styles.photoButtonContainer}><Button style={styles.button} title="select Photo" onPress={this.handleChoosePhoto} /></View>
            {this.state.photo && this.state.feedCaption && <View style={styles.uploadButtonContainer}><Button style={styles.button} title="Upload Photo" onPress={this.uploadFeed} /></View>}
          </ScrollView>
        </View>
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default UploadFeedScreen;
