import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  cardList: {
    backgroundColor: '#EBE9E9',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
});

export default styles;