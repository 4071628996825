export const LOGIN_API = 'https://myepchemicals.com:5040/login'
export const ORDER_API = 'https://epchemicalsmy.com:5010'
export const MESSAGE_API = 'https://epchemicalsmy.com:5020'
export const NOTIFICATION_API = 'https://epchemicalsmy.com:5030'
export const USER_API = 'https://myepchemicals.com:5040'
export const COMPANY_API = 'https://epchemicalsmy.com:5050'
export const SUPPLIER_API = 'https://epchemicalsmy.com:5060'
export const PRODUCT_API = 'https://epchemicalsmy.com:5070'
export const FEED_API = 'https://myepchemicals.com:5080'
export const PROJECT_API = 'https://epchemicalsmy.com:5090'
export const STATIC_PATH = 'https://myepchemicals.com/static/media/feed/'
export const CONSUMPTION_API = 'https://epchemicalsmy.com:5100'
export const CURRENT_VERSION = '1.8.2'
