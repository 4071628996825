import axios from 'axios';
import * as apis from '../configs/apis';

export const getCompany = async (token: string, companyId: string) => {
  try {
    const getCompanyResponse: any = await axios.get(apis.COMPANY_API + '/company/name/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCompanyResponse.data;
  } catch (err) {
    console.log(`Error returned from GET company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getCompanyById = async (token: string, companyId: string) => {
  try {
    const getCompanyResponse: any = await axios.get(apis.COMPANY_API + '/company/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCompanyResponse.data;
  } catch (err) {
    console.log(`Error returned from GET company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getSupplierAll = async (token: string) => {
  try {
    const getCompanyResponse: any = await axios.get(apis.COMPANY_API + '/company/supplier/all', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCompanyResponse.data;
  } catch (err) {
    console.log(`Error returned from GET company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getCompanyAll = async (token: string) => {
  try {
    const getCompanyResponse: any = await axios.get(apis.COMPANY_API + '/company/status/approved', { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCompanyResponse.data;
  } catch (err) {
    console.log(`Error returned from GET company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getCompanyBySales = async (token: string, salesId: string) => {
  try {
    const getCompanyResponse: any = await axios.get(apis.COMPANY_API + '/company/sales/' + salesId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getCompanyResponse.data;
  } catch (err) {
    console.log(`Error returned from GET company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postCompany = async (token: string, companyDetails: any) => {
  try {
    await axios.post(apis.COMPANY_API + '/company', companyDetails, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getProjectDevelopmentByCustomer = async (token: string, companyId: string) => {
  try {
    const getProjectDevelopmentResponse: any = await axios.get(apis.COMPANY_API + '/projectDevelopment/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProjectDevelopmentResponse.data;
  } catch (err) {
    console.log(`Error returned from GET project development API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getProjectDevelopmentBySupplier = async (token: string, supplierId: string) => {
  try {
    const getProjectDevelopmentResponse: any = await axios.get(apis.COMPANY_API + '/projectDevelopment/supplier/' + supplierId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProjectDevelopmentResponse.data;
  } catch (err) {
    console.log(`Error returned from GET project development API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postProjectDevelopment = async (token: string, projectDevelopment: any) => {
  try {
    await axios.post(apis.COMPANY_API + '/projectDevelopment', projectDevelopment, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST project development API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
