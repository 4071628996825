import axios from 'axios';
import * as apis from '../configs/apis';

export const getProjectDevelopmentById = async (token: string, projectId: string) => {
  try {
    const getProjectDevelopmentResponse: any = await axios.get(apis.PROJECT_API + '/project/id/' + projectId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProjectDevelopmentResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET project development by id API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getProjectDevelopmentByCustomer = async (token: string, companyId: string) => {
  try {
    const getProjectDevelopmentResponse: any = await axios.get(apis.PROJECT_API + '/project/companyId/' + companyId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProjectDevelopmentResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET project development by company API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const getProjectDevelopmentBySupplier = async (token: string, supplierId: string) => {
  try {
    const getProjectDevelopmentResponse: any = await axios.get(apis.PROJECT_API + '/project/supplierId/' + supplierId, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return getProjectDevelopmentResponse.data.data;
  } catch (err) {
    console.log(`Error returned from GET project development by supplier API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postProjectDevelopment = async (token: string, projectDevelopment: any) => {
  try {
    await axios.post(apis.PROJECT_API + '/project', projectDevelopment, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST project development API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}

export const postProjectDevelopmentById = async (token: string, projectId: string, projectDevelopment: any) => {
  try {
    await axios.post(apis.PROJECT_API + '/project/id/' + projectId, projectDevelopment, { headers: { 'Content-Type': 'application/json', 'authToken': token }, timeout: 2000 });
    return true;
  } catch (err) {
    console.log(`Error returned from POST project development by id API. Message: ${err}. Response: ${JSON.stringify(err.response)}`);
    return false;
  }
}
