import React, { Component } from 'react';
import App from './app/app';

export default class Main extends Component {
  render() {
    return (
      <App />
    );
  }
}
