import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  customerContainer: {
    flex: 1
  },
  emptyMessage: {
    alignSelf: 'center',
    justifyContent: 'center'
  },
  tableHeader: { 
    height: 70, 
    backgroundColor: '#BDBDBD' 
  },
  headerText: { 
    textAlign: 'center', 
    fontWeight: 'bold' 
  },
  tableText: { 
    textAlign: 'center', 
    fontWeight: '100' 
  },
  dataWrapper: { 
    marginTop: -1
  },
  tableRow: { 
    height: 60, 
    backgroundColor: 'white' 
  }
});

export default styles;