import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  feedContainer: {
    flex: 1
  },
  uploadForm: {
    width: 350,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  button: {
    width: 200,
    paddingTop: 10,
    alignSelf: 'center'
  },
  uploadButtonContainer: {
    width: 300,
    paddingTop: 30,
    alignSelf: 'center'
  },
  photoButtonContainer: {
    width: 280,
    paddingTop: 10,
    alignSelf: 'center'
  }
});

export default styles;