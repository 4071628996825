import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { NavigationEvents } from 'react-navigation';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './projectDevelopmentListing.styles';
import Header from '../components/header';
import CardList from '../components/cardList';
import BackBar from '../components/backBar';
import * as CompanyService from '../services/companyService';
import * as ProjectService from '../services/projectService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  customerListing: Array<any>;
}

class ProjectDevelopmentListing extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      customerListing: []
    }

    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    let customerListingResponse: any;
  
    if (this.props.screenProps.role === 'supplier') {
      customerListingResponse = await ProjectService.getProjectDevelopmentBySupplier(this.props.screenProps.token, this.props.screenProps.companyId);
    } else if (this.props.screenProps.role === 'sales') {
      customerListingResponse = await CompanyService.getCompanyBySales(this.props.screenProps.token, this.props.screenProps.userId);
    } else {
      customerListingResponse = await CompanyService.getCompanyAll(this.props.screenProps.token);
    }
    
    if (customerListingResponse) {
      let customerListing: Array<any> = [];
      
      if (this.props.screenProps.role === 'supplier') {
        customerListingResponse = customerListingResponse.map(item => item.companyId).filter((value, index, self) => self.indexOf(value) === index);
        
        customerListingResponse.map((item: any, key: any) => {   
          customerListing.push({
            'title': item,
            'content': '',
            'buttonText': 'SELECT',
            'buttonOnPress': () => this.onSelect(item)
          })
        });
      } else {
        customerListingResponse = customerListingResponse.filter(function (item: any) {
          return item.type === 'customer';
        });
        
        if (this.props.screenProps.role === 'management' && this.props.screenProps.department !== 'all' && this.props.screenProps.department !== 'super') {
          let departmentToFilter: string = this.props.screenProps.department;
        
          customerListingResponse = customerListingResponse.filter(function (item: any) {
            return item.department === 'all' || item.department === departmentToFilter;
          });
        }
    
        customerListingResponse.map((item: any, key: any) => (
          customerListing.push({
            'title': item.name,
            'content': '',
            'buttonText': 'SELECT',
            'buttonOnPress': () => this.onSelect(item.name)
          })
        ));
      }
      
      customerListing.sort((a, b) => a.title.localeCompare(b.title));

      this.setState({ customerListing: customerListing });
    }
  }

  onSelect(companyId: string) {
    this.props.navigation.navigate('ProjectDevelopmentListingByCustomer', {customerId: companyId});
  }

  render() {
    return (
      <View style={styles.customerContainer}>
        <NavigationEvents onDidFocus={() => this.componentDidMount()} />
        <Header headerText='Customer' onLogout={this.props.screenProps.onLogout} />
        {this.props.screenProps.role === 'sales' && <BackBar onPress={() => this.props.navigation.navigate('SalesScreen')} />}
        {this.state.customerListing.length === 0 && <Text style={styles.emptyMessage}>No customer!</Text>}
        {this.state.customerListing.length > 0 && <CardList dataList={this.state.customerListing} />}
      </View>
    );
  }
}

export default ProjectDevelopmentListing;