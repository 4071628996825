import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  homeContainer: {
    flex: 1
  },
  brandContainer: {
    backgroundColor: '#009102',
    flexGrow: 1, 
    alignContent: 'center',
    justifyContent: 'center',
    paddingLeft: 10
  },
  brandText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
    paddingLeft:20
  },
  item: {
    backgroundColor: '#F2F1F1',
    flexGrow: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingLeft: 10,
    borderColor: 'gray',
    borderWidth:0.5
  },
  itemText: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft:20
  }
});

export default styles;