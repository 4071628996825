import React, { Component } from 'react';
import { View } from 'react-native';
import { NavigationStackProp, NavigationStackScreenProps } from 'react-navigation-stack';
import styles from './createUserScreen.styles';
import Header from '../components/header';
import Form from '../components/form';
import Loader from '../components/loader';
import * as userService from '../services/userService';

interface CustomInputProps {
  navigation: NavigationStackProp;
  screenProps: NavigationStackScreenProps;
}

interface CustomInputStates {
  formInput: Array<any>;
  email: string;
  password: string;
  role: string;
  sales: string;
  salesList: Array<any>;
  salesOption: Array<any>;
  haveError: boolean;
  errorMessage: string;
  isLoading: boolean;
}

class CreateUserScreen extends Component<CustomInputProps, CustomInputStates> {
  constructor(props: CustomInputProps) {
    super(props);

    this.state = {
      formInput: [],
      email: '',
      password: '',
      role: '',
      sales: '',
      salesList: [],
      salesOption: [],
      haveError: false,
      errorMessage: '',
      isLoading: false
    }

    this.generateForm = this.generateForm.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeSales = this.onChangeSales.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  async componentDidMount() {
    let salesList = await userService.getSales(this.props.screenProps.token);

    let salesOption: Array<any> = [];
    
    salesList.map(function(item: any){salesOption.push(item.email)});

    this.setState({ salesOption: salesOption, salesList: salesList }, () => this.generateForm());
  }

  generateForm() {
    let formInput: Array<any> = [
      { placeholder: 'Username', onChangeText: this.onChangeEmail, value: this.state.email },
      { placeholder: 'Password', onChangeText: this.onChangePassword, secureTextEntry: true, value: this.state.password },
      { placeholder: 'Role', onChangeText: this.onChangeRole, value: this.state.role, option: ['sales', 'customer'], inputType: 'picker' }
    ];

    if (this.state.role === 'customer') {
      formInput.push({ placeholder: 'Sales', onChangeText: this.onChangeSales, value: this.state.sales, option: this.state.salesOption, inputType: 'picker' });
    }

    this.setState({ formInput: formInput});
  }

  onChangeEmail(input: string) {
    this.setState({ email: input }, () => this.generateForm());
  }

  onChangePassword(input: string) {
    this.setState({ password: input }, () => this.generateForm());
  }

  onChangeRole(input: string) {
    this.setState({ role: input }, () => this.generateForm());
  }

  onChangeSales(input: string) {
    this.setState({ sales: input }, () => this.generateForm());
  }

  async onCreate() {
    this.setState({ isLoading: true });

    let sales: string = '';

    if (this.state.sales) {
      let selectedSalesEmail: string = this.state.sales;

      let selectedSales: Array<any> = this.state.salesList.filter(function (item: any) {
        return item.email === selectedSalesEmail;
      });

      sales = selectedSales[0]._id;
    }

    const createUserResponse: any = await userService.createUser(this.props.screenProps.token, this.state.email, this.state.password, this.state.role, sales);

    if (createUserResponse) {
      this.setState({ haveError: true, isLoading: false, errorMessage: 'User created.' });
      this.componentDidMount();
    } else {
      this.setState({ haveError: true, isLoading: false, errorMessage: 'Error occured. Please try again.' });
    }
  }

  render() {
    return (
      <View style={styles.createUserContainer}>
        <Header headerText='Create User' onLogout={this.props.screenProps.onLogout} />
        <Form formInput={this.state.formInput} haveError={this.state.haveError} errorMessage={this.state.errorMessage} buttonText={'Create User'} buttonOnPress={this.onCreate} />
        <Loader isLoading={this.state.isLoading} />
      </View>
    );
  }
}

export default CreateUserScreen;